import React, {FC, useState} from 'react'
import {FaTimes} from 'react-icons/fa'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { imgs } from '../assets/images/img'
import {Footer} from '../components/footer/footer'
import {ModalComponent} from '../components/Modal'
import {NavLogged} from '../components/nav/navLogged'
import {clientLogout} from '../redux/actions/client.actions'
import {
    removeLocalStorage,
    getLocalStorage,
} from '../utils/functions'
import {TOKEN_KEY} from '../utils/constants/'


type pageProps = {
    component: React.ComponentType
    path?: string
}

export const ProtectedRoute: FC<pageProps> = ({component: RouteComponent}) => {
    const [modal, setmodal] = useState(false)
    const navigate = useNavigate()
    const modalContent = (
        <div className="bg-white   outline-none  space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2.5/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => setmodal(!modal)}
                    className="cursor-pointer"
                />
            </div>
            <div className="space-y-8 px-10 pt-5 pb-10">
                <div className="flex flex-col items-center">
                    <img
                        src={imgs.logo}
                        alt=""
                        className="h-6 w-full sm:h-16 object-contain"
                    />
                    <div className="self-end Roboto-Medium text-blue-base text-xs sm:text-sm ">
                        XPRESS
                    </div>
                </div>
                <div className="Roboto-Bold text-blue-base text-center text-xl lg:text-2xl">
                    Are you sure want to logout?
                </div>

                <div className="space-y-3">
                    <button
                        className="btn-brown w-full "
                        onClick={() => {
                            removeLocalStorage(TOKEN_KEY)
                            setmodal(!modal)
                            clientLogout(navigate)
                        }}>
                        YES
                    </button>
                    <button
                        className="w-full h-7 sm:h-8 md:h-10 hover:bg-blue-base hover:text-white bg-white text-blue-base text-xs sm:text-sm Roboto-Medium text-center duration-1000 "
                        onClick={() => {
                            setmodal(!modal)
                        }}
                    >
                        CANCEL
                    </button>
                </div>
            </div>
        </div>
    )
    return getLocalStorage(TOKEN_KEY) ? (
            <React.Fragment>
            <NavLogged onLogout={() => setmodal(!modal)} />
            <RouteComponent />
            <Footer  event={true}/>
            <ModalComponent
                modalState={modal}
                handleModal={() => setmodal(!modal)}
                content={modalContent}
            />
        </React.Fragment>) : (<Routes><Route path="*" element={<Navigate to={"/login"} />} /></Routes>) 
}

