export const PATH = {
    GET_EVENT: '/event/:eventId',
    PORTAL_TERMS_CONDITIONS: '/terms-conditions',
    EVENT_TERMS_CONDITIONS: '/event-term-conditions',
    SECURITIES_VALIDATION: '/validation/security-issue',
    ECONSENT_EMAIL: '/econsent-email',
    DEPOSIT_INSTRUCTION: '/deposit-instruction',
    DEPOSIT_INSTRUCTION_STEP_2: '/deposit-instruction-step-2',
    DEPOSIT_INSTRUCTION_STEP_3: '/deposit-instruction-step-3',
    DEPOSIT_INSTRUCTION_STEP_4: '/deposit-instruction-step-4',
    REVIEW_INSTRUCTION: '/review-instruction',
    CONFIRM_DOWNLOAD: '/confirm-download',

    WARRANT_VALIDATION: '/validation/warrant-issue',
    EXCERCISE_INSTRUCTION: '/exercise-instruction',
    REGISTRATION_INSTRUCTION_STEP_1: '/registration-instruction-step-1',
    REGISTRATION_INSTRUCTION_STEP_2: '/registration-instruction-step-2',
    WARRANT_DELIVERY_INSTRUCTIONS: '/delivery-instruction',
    WARRANT_PAYMENT: '/warrant-payment',

    ELECTION_DECLARATION: '/election-declaration',
    PAYMENT_METHODS: '/Payment-methods',
    RESIDENCY_INSTRUCTIONS: '/residency-instructions',
    TAX_CERTIFICATION_STEP_1: '/tax-certification-step-1',
    TAX_CERTIFICATION_STEP_2: '/tax-certification-step-2',
    TAX_CERTIFICATION_STEP_3: '/tax-certification-step-3',
    TAX_CERTIFICATION_STEP_4: '/tax-certification-step-4',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',


    CLIENT_LOGIN: '/login',
    CLIENT_HOME: '/user/home',
    CLIENT_SECURITY_HOLDER: '/user/event/securityholder/:eventId',
    CLIENT_EVENT_DETAIL: '/user/event/:id',
    CLIENT_TERMS_AND_CONDITIONS: '/user/terms-and-conditions',
    CLIENT_PRIVACY_POLICY: '/user/privacy-policy',
    UPDATE_PASSWORD: '/user/update-password'
    

}