import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../components/footer/footer'
import {Nav} from '../../components/nav/nav'
import {Header} from '../../components/header/header'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {updatePortalTermAndConditionStatus} from '../../redux/actions/auth.actions'
import { RootState } from '../../redux/rootstore'

export const TermsConditions = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const {event, event_conditions, user} = useSelector(
        (state: RootState) => state.VerificationReducer
    )
    const securityholder_detail = user && user.securityholder_detail

    
    const formik = useFormik({
        initialValues: {
            acceptedTerms: securityholder_detail?.mark_read_term_and_conditions_of_portal,
        },
        validationSchema: Yup.object({
            acceptedTerms: Yup.boolean()
                .required('Required')
                .oneOf([true], 'You must accept the terms and conditions.'),
        }),
        onSubmit: async (values) => {
            const { acceptedTerms } = values;
            if(securityholder_detail.mark_read_term_and_conditions_of_portal !== true){
                await dispatch(updatePortalTermAndConditionStatus(acceptedTerms, navigate))
            } else {
                navigate('/event-term-conditions')
            }
        },
    })
    return (
        <div className="w-full h-full flex flex-col items-center ">
            <Nav
                enableProps={true}
                // termsCondtion={false}
                // validation={false}
                // econsent={false}
                // instructions={false}
                // payment={false}
                // residency={false}
                // review={false}
                // confirmdownload={false}
                // taxconfirmation={false}
                // termsCondtionActive={true}
            />

            <Header heading={event.event_name} description={'Terms and Conditions'}/>
            <div className="w-11/12 md:w-9/12 mx-auto py-10">
                <div className="Roboto-Bold text-lg sm:text-xl md:text-2xl text-blue-base mx-auto text-center">
                    ODYSSEY TRUST COMPANY
                </div>
                <div className="space-y-4 mt-10 sm:mt-14">
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            TERMS & CONDITIONS GOVERNING YOUR USE OF THIS
                            WEBSITE (THE “TERMS”).
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            These Terms govern your use of all Odyssey Trust
                            Company Websites including the Online Tools
                            (hereinafter collectively referred to as the
                            “Websites”) offered by Odyssey Trust Company
                            (“Odyssey”).
                            <br /> If you access and use the Websites, you
                            accept and agree to be bound by and comply with the
                            (i) Terms, and (ii) any terms, conditions, legal
                            notices and disclaimers in the footers, content,
                            other pages of the Websites.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            YOUR AUTHORITY TO USE THE WEBSITES
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            By using the Websites, you are representing to us
                            that you have the power and authority to accept
                            these Terms of Use and to enter into this agreement
                            with us, that you are capable of assuming, and do
                            assume, any risks related to the use of the Websites
                            and their content, and that you understand and
                            accept the terms, conditions and risks relating to
                            their use.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            GENERAL
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            All services of Odyssey that are described, made
                            available or provided on the Websites may be changed
                            or withdrawn at any time without notice and are
                            subject to the terms and conditions of the
                            applicable agreements governing their use in force
                            at the time of the provision of the service, in
                            addition to the Terms. In the event that the Terms
                            are inconsistent with the terms of any agreement
                            that you or your organization may have with Odyssey,
                            the terms of that agreement will govern. The
                            information, material and content provided in the
                            pages of our Websites, is believed to be reliable
                            when posted, but there is no guarantee that it is
                            accurate, complete or current at all times. Without
                            limiting the generality of the foregoing, the
                            Information may include technical inaccuracies or
                            typographical errors, and Odyssey and their
                            officers, directors, employees and agents have no
                            obligation to update the Information. The Websites
                            may be changed, withdrawn or terminated at any time
                            without notice. Changes may be made to the Terms at
                            any time without notice by updating this posting.
                            You agree to review the Terms regularly, and your
                            continued access or use of the Websites will mean
                            that you agree to any changes.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            LOCAL LAW
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            Please be aware of the laws of your country or that
                            otherwise apply to you in relation to any of the
                            matters described in the pages of the Websites. If
                            you choose to access the Websites, you do so on your
                            own initiative and are responsible for compliance
                            with applicable local, national or international
                            laws.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            NOT OFFERING ANY ADVICE
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            The Information is for informational purposes only
                            and is not intended to provide specific financial,
                            investment, tax, legal, accounting or other advice
                            to you, and should not be acted or relied upon in
                            that regard without seeking the advice of a
                            professional. Your advisor can help to ensure that
                            your own circumstances have been properly considered
                            and any action is taken on the latest available
                            information.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            NO WARRANTIES
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            Odyssey provides the Websites on an “as is, where
                            is” basis and do not make any express or implied
                            warranties, representations, or endorsements with
                            respect to the Websites or the information provided
                            on the Websites, including without limitation,
                            warranties as usefulness, completeness, accuracy,
                            currency, reliability and fitness for a particular
                            purpose. Further, Odyssey does not represent or
                            warrant that the Websites will be available and meet
                            your requirements, that access will be
                            uninterrupted, that there will be no delays,
                            difficulties in use, defects, incompatibility,
                            failures, errors, omissions or loss of transmitted
                            information, that no viruses or other contaminating
                            or destructive properties will be transmitted or
                            that no damage will occur to your computer system.
                            You have sole responsibility for taking protection
                            and backup of data and/or equipment and for taking
                            reasonable and appropriate precautions to scan for
                            computer viruses or other destructive properties.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            PROTECTING INFORMATION
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            We have reasonable security measures in place to
                            protect against the loss, misuse and interception by
                            third parties of the information under our control.
                            However, complete confidentiality and security is
                            not yet possible over the Internet. We assume no
                            liability for any damages you may suffer as a result
                            of loss, interception, alteration or misuse of
                            information you provide us or any information
                            transmitted over the Internet. Please see our
                            Privacy Policy for further details.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            COPYRIGHT
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            All information, text, documents, materials,
                            graphics, photography, designs, logos, layouts,
                            icons and computer codes (collectively, “content”)
                            of this site is (and shall continue to be) owned
                            exclusively by Odyssey and is protected under
                            applicable copyrights, patents, trademarks, and/or
                            other proprietary intellectual property rights. The
                            copying, redistribution, use or publication of any
                            such content, or any part of this site, are
                            prohibited. Under no circumstances will you acquire
                            any ownership rights or other interest in any
                            content by or through your use of this site.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            INFORMATION PROVIDED BY YOU
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            You agree that any information that you provide to
                            Odyssey through our Websites shall become the sole
                            property of Odyssey, and may be used freely by
                            Odyssey. By providing such information, you declare
                            and warrant that you have all necessary rights to
                            submit such information and that the information
                            does not infringe the rights of third parties.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            NO LIABILITY
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            Odyssey is not responsible, and will not be liable
                            to you or anyone else, for any damages whatsoever
                            and howsoever caused (including direct, indirect,
                            incidental, special, consequential, exemplary or
                            punitive damages) arising out of or in connection
                            with the Websites or the Information, or your
                            ability on inability to access or use the Websites,
                            or any action or decision made by you in reliance on
                            the Websites or the information provided on the
                            Websites, or any errors in or omissions from the
                            Websites, or any unauthorized use or reproduction of
                            the Websites or the information provided on the
                            Websites.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            LINKS
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            The Websites include links between the Websites and
                            to other third-party websites. Links from or to
                            websites, including links to the Websites, are for
                            convenience only. Odyssey does not review or
                            control, and is not responsible for, any websites
                            linked from or to the Websites, the content of those
                            websites, the privacy practices of those websites,
                            the third parties named therein, or their products
                            or services. Linking to any other website is at your
                            sole risk and Odyssey will not be responsible or
                            liable for any damages in connection with such
                            linking. In addition, Odyssey does not endorse or
                            approve of any websites linked from or to the
                            Websites, except for Websites of Odyssey. You may
                            not gather, extract, reproduce and/or display on any
                            other non-Odyssey website or other online service,
                            any material on or from the Websites, whether or not
                            by the use of spiders or other ‘screen scraping’
                            software or system used to extract data without the
                            express consent of Odyssey.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            JURISDICTION
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            Our Websites (excluding linked websites) are
                            administered by Odyssey Trust Company from its
                            offices in Calgary. The Websites, the information
                            provided on the Websites and the Terms will be
                            governed by and construed in accordance with the
                            laws of the Province of Alberta and the laws of
                            Canada applicable therein, without giving effect to
                            any principles of conflicts of laws, and
                            notwithstanding your domicile, residence or physical
                            location. All disputes, controversies or claims
                            arising out of or in connection with the Websites or
                            the Terms shall be submitted to and be subject to
                            the exclusive jurisdiction of the courts of the
                            Province of Alberta. You submit and attorn to the
                            exclusive jurisdiction of the courts of the Province
                            of Alberta to finally adjudicate or determine any
                            suit, action or proceeding arising out of or in
                            connection with the Websites or the Terms.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            GENERAL INFORMATION
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            If any provision of these Terms or any other term or
                            condition found on this website is held to be
                            invalid or unenforceable, the validity or
                            enforceability of the remaining provisions will not
                            be affected, and to the extent feasible and
                            permitted by applicable law, the invalid or
                            unenforceable provision will be replaced with a
                            valid and enforceable provision that comes closest
                            to the intention underlying the invalid or
                            unenforceable provision.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            ADDITIONAL DISCLOSURES AND DISCLAIMERS
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            The information contained on the Websites is not
                            intended for distribution to or use by anyone, nor
                            is it intended to constitute an offer or
                            solicitation of trust or other services, in any
                            jurisdiction, state or country where such
                            distribution or use or the offering, solicitation or
                            providing of such services is contrary to applicable
                            law.
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="flex flex-col h-16">
                        <div className="text-left  flex items-center text-xs sm:text-sm space-x-2 mt-10">
                            <input
                                type="checkbox"
                                id="checked"
                                className="accent-[#0E294B] h-4 w-4 p-1 cursor-pointer outline-none ring-0 focus:ring-0"
                                name="acceptedTerms"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.acceptedTerms}
                            />
                            <label
                                htmlFor="checked"
                                className="text-blue-base Roboto-Medium mt-1 cursor-pointer">
                                I agree to the Terms and Conditions
                            </label>
                        </div>
                        {formik.touched.acceptedTerms &&
                            formik?.errors.acceptedTerms && (
                                <div className="Gilroy-Light text-xs text-reddark pl-5">
                                    {formik.errors.acceptedTerms}
                                </div>
                            )}
                    </div>
                    {formik.values.acceptedTerms && <div className="mt-10 flex items-center justify-end">
                        <button className="btn-brown w-44 " type="submit">
                            CONTINUE
                        </button>
                    </div>}
                </form>
            </div>

            <Footer />
        </div>
    )
}
