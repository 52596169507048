import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Footer } from '../../../../components/footer/footer'
import { Nav } from '../../../../components/nav/nav'
import { Checkbox } from '../../../../components/checkbox/checkbox'
import { Header } from '../../../../components/header/header'
import { FaArrowLeft, FaUpload } from 'react-icons/fa'
import { QuestionIcon } from '../../../../components/question'
import { handleUploadDoc, prepareConsolidationExchangeDetail } from '../../../../redux/actions/auth.actions'
import { useDispatch, useSelector } from 'react-redux'
import React, { useRef } from 'react'
import { RootState } from '../../../../redux/rootstore'
import { showToast } from '../../../../utils/functions'
import { TOAST_TYPE } from '../../../../utils/constants'

export const ElectionDeclaration = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    // const authorityFileInput = useRef<any>(null);
    const spouseFileInput = useRef<any>(null);
    const { event, user, consolidation_exchange_detail, consolidation_exchange } = useSelector((state: RootState) => state && state.VerificationReducer)

    const formik = useFormik({
        initialValues: {
            election_type: consolidation_exchange?.election?.election_type,
            residency_tax_declaration: consolidation_exchange?.election?.residency_tax_declaration,
            accredited_investor_status: consolidation_exchange?.election?.accredited_investor_status,
            individual_marital_status: consolidation_exchange?.election?.individual_marital_status,
            consent_of_spouse: consolidation_exchange?.election?.consent_of_spouse,
            // authority_doc: consolidation_exchange?.election?.authority_doc,
        },
        validationSchema: Yup.object({
            election_type: consolidation_exchange_detail.holder_make_an_election ? Yup.string().required('Election type is required') : Yup.string(),
            residency_tax_declaration: consolidation_exchange_detail.ask_residency_holder_status_question ? Yup.string().required() : Yup.string(),
            accredited_investor_status: consolidation_exchange_detail.accredited_investor_info_required ? Yup.string().required() : Yup.string(),
            individual_marital_status: Yup.string().when('accredited_investor_status', {
                is: 'individual',
                then: Yup.string().required()
            }),
            // authority_doc: Yup.string().when('accredited_investor_status', {
            //     is: 'entity',
            //     then: Yup.string().required('Authority document is required')
            // })
        }),
        onSubmit: async (values) => {
            let { election_type, accredited_investor_status, residency_tax_declaration, individual_marital_status } = values
            let prevConsolidation = {...consolidation_exchange}
            if(residency_tax_declaration == 2 && prevConsolidation.tax && prevConsolidation.tax.w8_form){
                delete prevConsolidation.tax.w8_form
            }
            if(residency_tax_declaration == 1 && prevConsolidation.w9form){
                delete prevConsolidation.w9form
            }
            delete prevConsolidation.election
            let input = {
                election: values
            }
            if(individual_marital_status === 'single' || accredited_investor_status === 'entity'){
                delete values.consent_of_spouse
            }
            if(election_type === "cash"){
                input["exchange_amount"] = 0;
                input["exchange_amount_after_fraction_treatment"] = 0;
            }
            await dispatch(prepareConsolidationExchangeDetail({...input, ...prevConsolidation}))
            if (election_type === 'cash' || election_type === 'both' || (consolidation_exchange_detail.entitlement && consolidation_exchange_detail.entitlement.toLowerCase() == 'cash')) {
                navigate('/Payment-methods')
            } else {
                if (consolidation_exchange_detail.ask_residency_holder_status_question) {
                    navigate('/residency-instructions')
                } else {
                    navigate('/review-instruction')
                }
            }
        },
    })
    const spouseFileInputChange = async (file: any) => {
        if (file) {
            if (file.target.files[0].type === 'application/pdf') {
                const res = await dispatch(handleUploadDoc(file.target.files[0]))
                if (res.status) {
                    let prevElection = consolidation_exchange.election
                    formik.setFieldValue("consent_of_spouse", res.data.location)
                    dispatch(prepareConsolidationExchangeDetail({ election: { ...prevElection, consent_of_spouse: res.data.location } }))
                } else {
                    showToast('Error uploading document, Try Again!', TOAST_TYPE.error)
                }
            } else {
                showToast('Only Pdf extensions are allowed', TOAST_TYPE.error)
            }
        }
    }
    // const authorityFileInputChange = async (file: any) => {
    //     if (file) {
    //         if (file.target.files[0].type === 'application/pdf') {
    //             const res = await dispatch(handleUploadDoc(file.target.files[0]))
    //             if (res.status) {
    //                 let prevElection = consolidation_exchange.election
    //                 formik.setFieldValue("authority_doc", res.data.location)
    //                 dispatch(prepareConsolidationExchangeDetail({ election: { ...prevElection, authority_doc: res.data.location } }))
    //             } else {
    //                 showToast('Error uploading document, Try Again!', TOAST_TYPE.error)
    //             }
    //         } else {
    //             showToast('Only Pdf extensions are allowed', TOAST_TYPE.error)
    //         }
    //     }
    // }
    return (
        <div className="w-full flex flex-col justify-between   min-h-screen h-screen">
            <div>
                <Nav enableProps={true} />
                <Header
                    heading={event.event_name}
                    description="Election and other Declarations"
                />
                <div className="page-padding space-y-8 py-10">
                    <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                        <FaArrowLeft /> <span>BACK</span>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="md:px-14 mx-auto flex flex-col space-y-10 accent-reddark">
                        {consolidation_exchange_detail.holder_make_an_election &&
                        <div className="space-y-1">
                            <div className="flex space-y-4 flex-col justify-between">
                                <div className="Roboto-Bold text-base md:text-xl xl:text-2xl 2xl:text-3xl text-blue-base">
                                    Election:
                                </div>
                                <div className="text-xs md:text-sm">
                                    The registered securityholder elects to
                                    receive the following, in accordance with
                                    the terms and conditions of the Transaction
                                    and Letter of Transmittal (as acknowledged
                                    and agreed to in a previous step):
                                </div>
                                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                                        <Checkbox
                                            text="Securities"
                                            name="election_type"
                                            id="Election1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'securities'}
                                            stateVal={formik.values.election_type}
                                        />
                                        <Checkbox
                                            text="Cash"
                                            name="election_type"
                                            id="Election2"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'cash'}
                                            stateVal={formik.values.election_type}
                                        />
                                        {consolidation_exchange_detail.securityholder_election_option == 2 &&
                                        <Checkbox
                                            text="Securities and Cash"
                                            name="election_type"
                                            id="Election3"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'both'}
                                            stateVal={formik.values.election_type}
                                        />}
                                    </div>
                            </div>
                            {formik.errors.election_type && (
                                <div className="text-reddark text-xs">
                                    {formik.errors.election_type}
                                </div>
                            )}
                        </div>}
                        {consolidation_exchange_detail.ask_residency_holder_status_question &&
                            <React.Fragment>
                                <hr className="border border-gray2" />
                                <div className="space-y-1">
                                    <div className="flex space-y-4 flex-col justify-between">
                                        <div className="Roboto-Bold text-base md:text-xl xl:text-2xl 2xl:text-3xl text-blue-base">
                                            Tax Declaration
                                        </div>
                                        <div className="text-xs md:text-sm">
                                            Indicate whether you are not a US Holder, or
                                            are a US Holder or acting on behalf of a US
                                            Holder(as defined in the Terms and
                                            Conditions previously agreed to):
                                        </div>
                                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
                                            <div className="flex items-center space-x-2">
                                                <Checkbox
                                                    text="Not a U.S. Entity or U.S. Citizen"
                                                    name="residency_tax_declaration"
                                                    id="ResidencyAndTaxDeclaration1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'1'}
                                                    stateVal={formik.values.residency_tax_declaration}
                                                />
                                                {/* <QuestionIcon /> */}
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <Checkbox
                                                    text="U.S. Entity or U.S. Citizen"
                                                    name="residency_tax_declaration"
                                                    id="ResidencyAndTaxDeclaration2"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'2'}
                                                    stateVal={formik.values.residency_tax_declaration}
                                                />
                                                {/* <QuestionIcon /> */}
                                            </div>
                                        </div>
                                    </div>
                                    {formik.errors.residency_tax_declaration && (
                                        <div className="text-reddark text-xs">
                                            {formik.errors.residency_tax_declaration}
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        }
                        {consolidation_exchange_detail.accredited_investor_info_required &&
                            <React.Fragment>
                                <hr className="border border-gray2" />
                                <div className="space-y-1">
                                    <div className="flex space-y-4 flex-col justify-between">
                                        <div className="Roboto-Bold text-base md:text-xl xl:text-2xl 2xl:text-3xl text-blue-base">
                                            Accredited Investor Status
                                        </div>
                                        <div className="text-xs md:text-sm">
                                            The registered security holder certifies
                                            that, in accordance with the terms and
                                            conditions of the Transaction and Letter of
                                            Transmittal acknowledged and agreed to in a
                                            previous step, it holds the following status
                                            as an accredited investor:
                                        </div>
                                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                                            <div className="flex items-center space-x-2">
                                                <Checkbox
                                                    text="Individual"
                                                    name="accredited_investor_status"
                                                    id="AccreditedInvestorStatus1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'individual'}
                                                    stateVal={formik.values.accredited_investor_status}
                                                />
                                                {/* <QuestionIcon /> */}
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <Checkbox
                                                    text="Entity"
                                                    name="accredited_investor_status"
                                                    id="AccreditedInvestorStatus2"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'entity'}
                                                    stateVal={formik.values.accredited_investor_status}
                                                />
                                                {/* <QuestionIcon /> */}
                                            </div>
                                        </div>
                                    </div>
                                    {formik.errors.accredited_investor_status && (
                                        <div className="text-reddark text-xs">
                                            {formik.errors.accredited_investor_status}
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>}
                        {formik.values.accredited_investor_status && formik.values.accredited_investor_status === 'individual' &&
                            <React.Fragment>
                                <hr className="border border-gray2" />
                                <div className="space-y-1">
                                    <div className="flex space-y-4 flex-col justify-between">
                                        <div className="Roboto-Bold text-base md:text-xl xl:text-2xl 2xl:text-3xl text-blue-base">
                                            Individual's Martial Status:
                                        </div>
                                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                                            <Checkbox
                                                text="Single"
                                                name="individual_marital_status"
                                                id="MartialStatus1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'single'}
                                                stateVal={formik.values.individual_marital_status}
                                            />
                                            <Checkbox
                                                text="Married"
                                                name="individual_marital_status"
                                                id="MartialStatus2"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'married'}
                                                stateVal={formik.values.individual_marital_status}
                                            />
                                        </div>
                                    </div>
                                    {formik.errors.individual_marital_status && (
                                        <div className="text-reddark text-xs">
                                            {formik.errors.individual_marital_status}
                                        </div>
                                    )}
                                </div>
                                {formik.values.individual_marital_status && formik.values.individual_marital_status === 'married' &&
                                    <React.Fragment>
                                        <div className="text-xs md:text-sm">
                                            Note that because you are an individual accredited
                                            investor and are married, you may be required to
                                            upload a Consent of Spouse if you reside in a state
                                            that requires your spouse to consent. For more
                                            details please refer to the Terms and Conditions in
                                            the Letter of Transmittal, and if applicable, please
                                            upload a copy of your spouse's completed and signed
                                            consent here.
                                        </div>
                                        <div className="flex flex-col md:flex-row md:items-center md:space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium py-5">
                                            <div className="Roboto-Bold text-sm sm:text-base  text-blue-base md:text-center">
                                                Upload Consent of Spouse
                                            </div>
                                            <button onClick={() => spouseFileInput.current.click()} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center" type="button" >
                                                <FaUpload />
                                                <input ref={spouseFileInput} style={{ display: 'none' }} type="file" accept="application/pdf" onChange={(e) => spouseFileInputChange(e)} />
                                                <span>Upload</span>
                                            </button>
                                            {formik.values.consent_of_spouse && <iframe src={formik.values.consent_of_spouse} width={300}  />}
                                            {formik.errors.consent_of_spouse && (
                                                <div className="text-reddark text-xs">
                                                    {formik.errors.consent_of_spouse}
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>}
                        {/* {formik.values.accredited_investor_status && formik.values.accredited_investor_status === 'entity' &&
                            consolidation_exchange_detail.signing_authority_required &&
                            <React.Fragment>
                                <div className="text-xs md:text-sm">
                                    If your securities are registered in the name of a corporation,
                                    proprietorship, LLC, LLP, partnership, or trust, we require a copy
                                    of the legal document that confirms your authority to submit and
                                    sign these instructions.
                                </div>
                                <div className="flex items-center space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium py-5 sm:w-10/12">
                                    <div className="Roboto-Bold text-sm sm:text-base  text-blue-base md:text-center">
                                        Upload Authority Document
                                    </div>
                                    <button onClick={() => authorityFileInput.current.click()} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center" type="button" >
                                        <FaUpload />
                                        <input ref={authorityFileInput} style={{ display: 'none' }} type="file" accept="application/pdf" onChange={(e) => authorityFileInputChange(e)} />
                                        <span>Upload</span>
                                    </button>
                                    {formik.values.authority_doc && <iframe src={formik.values.authority_doc} width={300}  />}
                                    {formik.errors.authority_doc && (
                                        <div className="text-reddark text-xs">
                                            {formik.errors.authority_doc}
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>} */}
                        <div className="mt-10  flex flex-col items-end space-y-6 justify-end w-full ">
                            <button className="btn-brown w-44" type="submit">
                                CONTINUE
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    )
}


