import {FC} from 'react'
import {STATES} from '../../utils/constants/states'

type inputWithLabelProps = {
    label: string
    name: string
    onChange: any
    onBlur: any
    value: any
    errors: any
    touched: any
    width: string
    countryCode?: string
}

export const SelectStateWithLabel: FC<inputWithLabelProps> = (props) => {

    return(<div className="space-y-1">
        <div className="flex flex-col space-y-1 w-full">
            <label htmlFor={props.name} className="text-xs Roboto-Medium">
                {props.label}
            </label>
            <div
                className={`focus:border-bordergray h-7 sm:h-8 md:h-10 focus:outline-none py-1 text-gray w-full px-2 text-xs sm:text-sm 2xl:text-base border  ${
                    props?.touched && props?.errors
                        ? 'border-reddark'
                        : 'border-bordergray'
                } `}>
                <select
                    id={props.name}
                    {...props}
                    className={` w-full h-full outline-none  text-gray border-none`}>
                        <option value={''}>
                            Select the value
                        </option>
                    {STATES[props.countryCode].map((item: any, index: any) => (
                        <option key={index} value={item.name}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
        {props?.touched && props?.errors && (
            <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                {props?.errors}
            </div>
        )}
    </div>
)
        }
