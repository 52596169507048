import { FC } from 'react'

type inputWithLabelProps = {
    label?: string
    placeholder?: string
    name: string
    onChange: any
    onBlur: any
    value?: any
    errors?: any
    touched?: any
    width?: string
    list?: any
    customList?: any
    extraOption?: any
    disabled?: any
}

export const SelectWithLabel: FC<inputWithLabelProps> = (props) => (
    <div className="space-y-1">
        <div className="flex flex-col space-y-1 w-full">
            <label htmlFor={props.name} className="text-xs Roboto-Medium">
                {props.label}
            </label>
            <div
                className={`h-7 sm:h-8 md:h-10  focus:border-bordergray focus:outline-none  text-gray w-full px-2 py-1 text-xs sm:text-sm 2xl:text-base border ${props?.touched && props?.errors
                    ? 'border-reddark'
                    : 'border-bordergray'
                    } `}>
                <select
                    id={props.name}
                    {...props}
                    className={`w-full h-full outline-none  text-gray border-none`}>
                    {
                        props?.extraOption && <option value={''}>
                            {props?.extraOption}
                        </option>
                    }
                    {
                        props?.customList ?
                            props?.customList
                            :
                            props.list.map((item: any, index: any) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))
                    }
                </select>
            </div>
        </div>
        {props?.touched && props?.errors && (
            <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                {props?.errors}
            </div>
        )}
    </div>
)
