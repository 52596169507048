import {useState} from 'react' 
import {ReactMaterialTable} from '../../components/table/customtable'
 

export const AdminReports = () => {
    const [tableData, settableData] = useState([
        {
            event_id: 'event 1',
            login_info: '12/23/2023',
            submissions: '12/23/2023',
        },
        {
            event_id: 'event 2',
            login_info: '12/23/2023',
            submissions: '12/23/2023',
        },
        {
            event_id: 'event 3',
            login_info: '12/23/2023',
            submissions: '12/23/2023',
        },
    ])

 
    const [searchedList, setsearchedList] = useState<
        {
            event_id: string
            login_info: string
            submissions: string
        }[]
    >([])

    const onEnterInput = (e: any, searchedBy: string, input: boolean) => {
        e.target.value !== ''
            ? setsearchedList(
                  tableData &&
                      tableData.filter((item: any) =>
                          item[`${searchedBy}`]
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                      )
              )
            : setsearchedList([])
    }

    var columns = [
        {
            field: 'event_id',
            title: 'EVENT ID',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.event_id}</div>
                </div>
            ),
        },
        {
            field: 'login_info',
            title: 'LOGIN INFO',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.login_info}</div>
                </div>
            ),
        },
        {
            field: 'submissions',
            title: 'SUBMISSIONS',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.submissions}
                    </div>
                </div>
            ),
        },

        {
            field: 'submissions',
            title: '',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div
                        className="text-brown Roboto-Bold"
                        // onClick={() => navigate('/admin-event-detail')}
                        >
                        SEE DETAILS
                    </div>
                </div>
            ),
        },
    ]
    return (
        <div className="page-padding py-10">
            <div className="flex items-center w-full space-y-4 md:space-y-0 flex-col md:flex-row md:w-8/12 lg:w-1/2 md:space-x-10">
                <div
                    className={`md:w-1/3 h-7 sm:h-8 flex items-center px-2 text-blue-normal md:h-10 outline-none border border-blue-base Roboto-Medium text-sm`}>
                    <select
                        onChange={(e: any) =>
                            onEnterInput(e, 'event_status', false)
                        }
                        className={`h-full outline-none w-full`}>
                        <option value="EVENT_STATUS">SELECT THE EVENT</option>
                        {tableData.map((item: any, index: any) => (
                            <option key={index} value={item.event_status}>
                                {item.event_status}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="py-10">
                <ReactMaterialTable
                    columns={columns}
                    data={searchedList.length > 0 ? searchedList : tableData}
                    searchbar={false}
                    toolbar={false}
                    exportbtn={false}
                    fontSize="16px"
                    pagging={true}
                    id="table"
                />
            </div>
        </div>
    )
}
