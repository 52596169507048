import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../../components/button/button'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {Header} from '../../../../components/header/header'
import {Input} from '../../../../components/input/input'
import {arrangementEvent, consolidationReverse} from '../../../constants'

export const ArrangementEvent = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            Securities: '',
        },
        validationSchema: Yup.object({
            Securities: Yup.string()
                .matches(/^[0-9,.]*$/)
                .required('Number of Securities Required'),
        }),
        onSubmit: (values) => {
            console.log(values)
            alert(JSON.stringify(values, null, 2))
            navigate('/security-holder/arrangement-event-entitlement')
        },
    })

    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav enableProps={true} />
            <Header heading="Additional Validation Step" />
            <div className="w-11/12 md:w-9/12 mx-auto py-5 flex flex-col items-center justify-center space-y-10">
                <div className="space-y-4  ">
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                            Please enter the total number of securities you
                            currently hold in [name of securities issue]
                        </div>
                    </div>
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className="mt-10 sm:w-1/2 md:w-1/3 flex flex-col px-5 items-center  space-y-3">
                    <Input
                        placeholder="Enter number of Securities"
                        name="Securities"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Securities}
                        errors={formik?.errors?.Securities}
                        touched={formik?.touched?.Securities}
                        width="full"
                    />
                    <button className="btn-brown-outline w-full " type="submit">
                        SUBMIT
                    </button>
                </form>
            </div>
            <div className="text-left w-full px-5 sm:px-10 md:px-20 my-10 ">
                <div className="Roboto-Bold text-base md:text-2xl text-blue-base mb-10">
                    Plan of Arrangement (Securities and/or Cash Entitlement)
                </div>
                <div className="space-y-2">
                    {arrangementEvent.map((item, index) => (
                        <div
                            key={index}
                            className="border-b border-gray2 text-xs sm:text-sm Roboto-Medium py-2">
                            {item}
                        </div>
                    ))}
                </div>

                <button className="btn-blue-outline w-44 mt-5" type="submit">
                    SUBMIT
                </button>

                <div className="w-full  flex justify-end mt-5">
                    <div className="space-y-2">
                        <div className="text-xs sm:text-sm md:text-base text-blue-base Roboto-Bold text-right">
                            eConsent Flag
                        </div>
                        <button
                            onClick={() =>
                                navigate(
                                    '/security-holder/arrangement-event-entitlement'
                                )
                            }
                            className="btn-brown w-44"
                            type="button">
                            Issue entitlement(s)
                        </button>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
