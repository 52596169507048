// import Timeline from '@material-ui/lab/Timeline'
// import TimelineItem from '@material-ui/lab/TimelineItem'
// import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
// import TimelineConnector from '@material-ui/lab/TimelineConnector'
// import TimelineContent from '@material-ui/lab/TimelineContent'
// // import TimelineDot from '@material-ui/lab/TimelineDot'
// import {makeStyles, Paper} from '@material-ui/core'
// import {Check, CheckBox, CheckCircle, Radio, RadioButtonChecked, RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined} from '@material-ui/icons'

// const useStyles = makeStyles({
//     timeline: {
//         transform: 'rotate(90deg)',
//     },
//     TimeLineContainer:{
//         // margin:'10px 0',
//     },
//     timelineContentContainer: {
//         textAlign: 'left',
//     },
//     timelineContent: {
//         display: 'inline-block',

//         // marginRight:"100px",
//         // marginLeft:"-350px",
//         transform: 'rotate(-90deg)',
//         textAlign: 'center',
//         // minWidth: 100,
//         background:'transparent',
//         border:"none"
//     },
//     timelineIcon: {
//         transform: 'rotate(-90deg)',
//     },
// })

// export const TimelineComponent = () => {
//     const classes = useStyles()
//     return (
//         <Timeline
//             className={classes.timeline}
//             // align="alternate"
//         >
//             <TimelineItem >
//                 <TimelineSeparator>
//                     <CheckCircle color="primary" className={classes.timelineIcon} />
//                     <TimelineConnector />
//                 </TimelineSeparator>
//                 <TimelineContent className={classes.timelineContentContainer}>
//                     <Paper className={classes.timelineContent}>
//                         <div className="text-blue-base text-xms">
//                             Confirm Download
//                         </div>
//                     </Paper>
//                 </TimelineContent>
//             </TimelineItem>
//             <TimelineItem className={classes.TimeLineContainer}>
//                 <TimelineSeparator>
//                     <RadioButtonUncheckedOutlined color="primary" className={classes.timelineIcon} />
//                     <TimelineConnector />
//                 </TimelineSeparator>
//                 <TimelineContent className={classes.timelineContentContainer}>
//                     <Paper className={classes.timelineContent}>
//                         <div className="text-blue-base text-xms">Review</div>
//                     </Paper>
//                 </TimelineContent>
//             </TimelineItem>
//             <TimelineItem className={classes.TimeLineContainer}>
//                 <TimelineSeparator>
//                     <RadioButtonUncheckedOutlined
//                         color="primary"
//                         className={classes.timelineIcon}
//                     />
//                     <TimelineConnector />
//                 </TimelineSeparator>
//                 <TimelineContent className={classes.timelineContentContainer}>
//                     <Paper className={classes.timelineContent}>
//                         <div className="text-blue-base text-xms">
//                             Tax Confirmation
//                         </div>
//                     </Paper>
//                 </TimelineContent>
//             </TimelineItem>
//             <TimelineItem className={classes.TimeLineContainer}>
//                 <TimelineSeparator>
//                     <RadioButtonUncheckedOutlined color="primary" className={classes.timelineIcon} />
//                     <TimelineConnector />
//                 </TimelineSeparator>
//                 <TimelineContent className={classes.timelineContentContainer}>
//                     <Paper className={classes.timelineContent}>
//                         <div className="text-blue-base text-xms">Residency</div>
//                     </Paper>
//                 </TimelineContent>
//             </TimelineItem>
//             <TimelineItem className={classes.TimeLineContainer}>
//                 <TimelineSeparator>
//                     <RadioButtonUncheckedOutlined color="primary" className={classes.timelineIcon} />
//                     <TimelineConnector />
//                 </TimelineSeparator>
//                 <TimelineContent className={classes.timelineContentContainer}>
//                     <Paper className={classes.timelineContent}>
//                         <div className="text-blue-base text-xms">Payment</div>
//                     </Paper>
//                 </TimelineContent>
//             </TimelineItem>
//             <TimelineItem className={classes.TimeLineContainer}>
//                 <TimelineSeparator>
//                     <RadioButtonUncheckedOutlined color="primary" className={classes.timelineIcon} />
//                     <TimelineConnector />
//                 </TimelineSeparator>
//                 <TimelineContent className={classes.timelineContentContainer}>
//                     <Paper className={classes.timelineContent}>
//                         <div className="text-blue-base text-xms">
//                             Instructions
//                         </div>
//                     </Paper>
//                 </TimelineContent>
//             </TimelineItem>
//             <TimelineItem className={classes.TimeLineContainer}>
//                 <TimelineSeparator>
//                     <RadioButtonUncheckedOutlined color="primary" className={classes.timelineIcon} />
//                     <TimelineConnector />
//                 </TimelineSeparator>
//                 <TimelineContent className={classes.timelineContentContainer}>
//                     <Paper className={classes.timelineContent}>
//                         <div className="text-blue-base text-xms">E-Consent</div>
//                     </Paper>
//                 </TimelineContent>
//             </TimelineItem>
//             <TimelineItem className={classes.TimeLineContainer}>
//                 <TimelineSeparator>
//                     <RadioButtonUncheckedOutlined color="primary" className={classes.timelineIcon} />
//                     <TimelineConnector />
//                 </TimelineSeparator>
//                 <TimelineContent className={classes.timelineContentContainer}>
//                     <Paper className={classes.timelineContent}>
//                         <div className="text-blue-base text-xms">
//                             Validations
//                         </div>
//                     </Paper>
//                 </TimelineContent>
//             </TimelineItem>
//             <TimelineItem className={classes.TimeLineContainer}>
//                 <TimelineSeparator>
//                     <RadioButtonCheckedOutlined color="primary" className={classes.timelineIcon} />
//                 </TimelineSeparator>
//                 <TimelineContent className={classes.timelineContentContainer}>
//                     <Paper className={classes.timelineContent}>
//                         {/* <Typography>Eat</Typography> */}
//                         <div className="text-blue-base text-xms">
//                             Terms and Conditions
//                         </div>
//                     </Paper>
//                 </TimelineContent>
//             </TimelineItem>
//         </Timeline>
//     )
// }

import Stepper from '@material-ui/core/Stepper'
import StepConnector from "@material-ui/core/StepConnector";
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import {makeStyles, styled, withStyles} from '@material-ui/core'
import {
    CheckCircle,
    RadioButtonCheckedOutlined,
    RadioButtonUnchecked,
} from '@material-ui/icons'
import {useSelector} from 'react-redux'
import { PATH } from '../../utils/constants/path'
import { useEffect, useState } from 'react'

const initialSteps = [
    'Terms and Conditions',
    'Validation',
    'E-Consent',
    'Instructions',
    //Include in case of warrent
    //'Payment',
    //Include both in case of plan of agreement
    //'Residency',
    //'Tax Confirmation',
    'Review',
    'Confirm Download',
]

const useStyles = makeStyles((theme) => ({
    customLabelStyle: {
        fontSize: '10px',
        marginTop: '1px !important',
        //whiteSpace: 'nowrap',
        color: 'black',
    },
}))
const CustomizedConnector = withStyles({
alternativeLabel: {
    top: 7,
    left: "calc(-50% + 5px)",
    right: "calc(50% + 5px)"
  },
  active: {
    "& $line": {
      borderColor: "rgb(14 41 75)"
    }
  },
  completed: {
    "& $line": {
      borderColor: "rgb(14 41 75)"
    }
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 2,
    //borderRadius: 1
  }
})(StepConnector);
const QontoStepIconRoot = styled('div')(({theme, ownerstate}) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    //height: 20,
    alignItems: 'center',
    backgroundColor: '#fff',
    ...(ownerstate.active && {
        color: '#784af4',
    }),

    '& .QontoStepIcon-completedIcon': {
        color: '#244D81',
        zIndex: 1,
        fontSize: 16,
        borderRadius: '100%',
    },
    '& .QontoStepIcon-circle': {
       // width: 8,
       // height: 8,
        borderRadius: '50%',
        backgroundColor: '#fff',
    },
}))

function QontoStepIcon(props) {
    const {active, completed, className} = props

    return (
        <QontoStepIconRoot ownerstate={{active}} className={className}>
            {completed ? (
                <CheckCircle
                    style={{
                        // borderRadius: '100%!important',
                        // width: '20px',
                        // height: '20px',
                    }}
                    className="QontoStepIcon-completedIcon"
                />
            ) : active ? (
                <RadioButtonCheckedOutlined className="QontoStepIcon-completedIcon" />
            ) : (
                <RadioButtonUnchecked className="QontoStepIcon-completedIcon" />
            )}
        </QontoStepIconRoot>
    )
}

export const TimelineComponent = () => {
    const classes = useStyles()
    const { event } = useSelector((state) => state.VerificationReducer)
    const [statusBar, setStatusBar] = useState(0)
    const [steps, setSteps] = useState(initialSteps)

    useEffect(()=>{
      getActiveStep()   
    },[window.location.pathname])

    useEffect(()=>{
        let tempSteps = [...steps];
        if(event.eventtype_id === 2 && tempSteps[4] !==  "Payment"){
            tempSteps.splice(4, 0, "Payment");
        }
        if(event.eventtype_id === 3){
            tempSteps[4] !==  "Payment" && tempSteps.splice(4, 0, "Payment") 
            tempSteps[5] !==  "Residency" && tempSteps.splice(5,0,'Residency')
            tempSteps[6] !==  "Tax Certification" && tempSteps.splice(6,0,'Tax Certification')
        }
        setSteps(tempSteps);
    },[event])

    const getStatus = (acc=0) => {
        if(event.eventtype_id === 1){
            return 4+acc
        } else if(event.eventtype_id === 2){
            return 5+acc
        }else {
            return 7+acc
        }
    }
    const pathStatus = {
        [PATH.PORTAL_TERMS_CONDITIONS] : 0,
        [PATH.EVENT_TERMS_CONDITIONS] : 0,
        [PATH.SECURITIES_VALIDATION] : 1,
        [PATH.WARRANT_VALIDATION] : 1,
        [PATH.ECONSENT_EMAIL] : 2,
        [PATH.REVIEW_INSTRUCTION] : getStatus(),
        [PATH.CONFIRM_DOWNLOAD] : getStatus(1),

        [PATH.DEPOSIT_INSTRUCTION] : 3,
        [PATH.DEPOSIT_INSTRUCTION_STEP_2] : 3,
        [PATH.DEPOSIT_INSTRUCTION_STEP_3] : 3,
        [PATH.DEPOSIT_INSTRUCTION_STEP_4] : 3,

        [PATH.EXCERCISE_INSTRUCTION] : 3,
        [PATH.REGISTRATION_INSTRUCTION_STEP_1] : 3,
        [PATH.REGISTRATION_INSTRUCTION_STEP_2] : 3,
        [PATH.WARRANT_DELIVERY_INSTRUCTIONS] : 3,
        [PATH.WARRANT_PAYMENT] : 4,

        [PATH.ELECTION_DECLARATION] : 3,
        [PATH.PAYMENT_METHODS]: 4,
        [PATH.RESIDENCY_INSTRUCTIONS]: 5,
        [PATH.TAX_CERTIFICATION_STEP_1]: 6,
        [PATH.TAX_CERTIFICATION_STEP_2]: 6,
        [PATH.TAX_CERTIFICATION_STEP_3]: 6,
        [PATH.TAX_CERTIFICATION_STEP_4]: 6,

    }
    const getActiveStep = ()=>{
        const path = window.location.pathname
        const statusValue = pathStatus[path];
        setStatusBar(statusValue?statusValue:0)


        // if(event.eventtype_id === 1){
        //     var regex = new RegExp(PATH.DEPOSIT_INSTRUCTION);
        //     if(path === PATH.PORTAL_TERMS_CONDITIONS || path === PATH.EVENT_TERMS_CONDITIONS){
        //         setStatusBar(0)
        //     } else if(path === PATH.SECURITIES_VALIDATION) {
        //         setStatusBar(1)
        //     } else if(path === PATH.ECONSENT_EMAIL) {
        //         setStatusBar(2)
        //     } else if(regex.test(path)) {
        //         setStatusBar(3)
        //     } else if(path === PATH.REVIEW_INSTRUCTION) {
        //         setStatusBar(4)
        //     } else if(path === PATH.CONFIRM_DOWNLOAD) {
        //         setStatusBar(5)
        //     }
        // } else if(event.eventtype_id === 2){
        //     if(path === PATH.PORTAL_TERMS_CONDITIONS || path === PATH.EVENT_TERMS_CONDITIONS){
        //         setStatusBar(0)
        //     } else if(path === PATH.WARRANT_VALIDATION) {
        //         setStatusBar(1)
        //     } else if(path === PATH.ECONSENT_EMAIL) {
        //         setStatusBar(2)
        //     } else if(path === PATH.EXCERCISE_INSTRUCTION
        //     || path === PATH.REGISTRATION_INSTRUCTION_STEP_1
        //     || path === PATH.REGISTRATION_INSTRUCTION_STEP_2
        //     || path === PATH.WARRANT_DELIVERY_INSTRUCTIONS) {
        //         setStatusBar(3)
        //     } else if(path === PATH.WARRANT_PAYMENT) {
        //         setStatusBar(4)
        //     } else if(path === PATH.REVIEW_INSTRUCTION) {
        //         setStatusBar(5)
        //     } else if(path === PATH.CONFIRM_DOWNLOAD) {
        //         setStatusBar(6)
        //     }
        // }
    }

    return (
        <div className="hidden md:block md:w-full lg:w-1/2 mt-1">
            <Stepper activeStep={statusBar} alternativeLabel connector={<CustomizedConnector />}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={QontoStepIcon}
                            classes={{label: classes.customLabelStyle}}>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}
