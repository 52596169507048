import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../../components/button/button'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'

import {InputWithLabel} from '../../../../components/InputWithLabel/InputWithLabel'

import {SelectStateWithLabel} from '../../../../components/selectWithLabel/selectStateWithLabel'
import {SelectCountryWithLabel} from '../../../../components/selectWithLabel/selectCountryWithLabel'
import {Checkbox} from '../../../../components/checkbox/checkbox'
import {FaUpload} from 'react-icons/fa'

export const ArrangementDilivery = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            streetAddress: '',
            city: '',
            zipCode: '',
            State: '',
            Country: '',
            DiliveryOption: false,
        },
        validationSchema: Yup.object({
            streetAddress: Yup.string().required('Street Address Required'),
            city: Yup.string().required('City Required'),
            Country: Yup.string().required('Country Required'),
            zipCode: Yup.string().required('Zip Code Required'),
        }),
        onSubmit: (values) => {
            console.log(values)
            alert(JSON.stringify(values, null, 2))
            navigate('/security-holder/arrangement-questions')
        },
    })
    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav enableProps={true} />
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="w-11/12 md:w-9/12 mx-auto flex flex-col py-12 space-y-10">
                <div className="space-y-3 md:space-y-6">
                    <div className="Roboto-Bold text-base md:text-xl text-blue-base ">
                        Delivery Instructions
                    </div>
                    <div className="Roboto-Medium text-sm text-blue-base">
                        "Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum." "Sed ut perspiciatis unde omnis
                        iste natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo
                        inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                        voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem
                        sequi nesciunt.
                    </div>
                </div>
                <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                    <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                        Choose Delivery Option:
                    </div>
                    <Checkbox
                        text="Deliver to registered address"
                        name="DiliveryOption"
                        id="DiliveryOption1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DiliveryOption}
                    />
                    <Checkbox
                        text="Hold for pick-up"
                        name="DiliveryOption"
                        id="DiliveryOption2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DiliveryOption}
                    />
                    <Checkbox
                        text="Deliver to alternate mailing address"
                        name="DiliveryOption"
                        id="DiliveryOption3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DiliveryOption}
                    />
                </div>
                <hr className="border border-gray2" />
                <div className="mt-10 space-y-8">
                    <div className="grid md:grid-cols-2 gap-4 md:gap-16">
                        <div className="space-y-3">
                            <InputWithLabel
                                label="Street Address"
                                placeholder="Please enter street"
                                name="streetAddress"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.streetAddress}
                                errors={formik?.errors?.streetAddress}
                                touched={formik?.touched?.streetAddress}
                                width="full"
                            />
                            <InputWithLabel
                                label="City"
                                placeholder="Please enter city"
                                name="city"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                                errors={formik?.errors?.city}
                                touched={formik?.touched?.city}
                                width="full"
                            />
                            <InputWithLabel
                                label="Zip/Postal Code"
                                placeholder="Please enter Zip code"
                                name="zipCode"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.zipCode}
                                errors={formik?.errors?.zipCode}
                                touched={formik?.touched?.zipCode}
                                width="full"
                            />
                        </div>
                        <div className="space-y-3">
                            <SelectStateWithLabel
                                label="State"
                                name="State"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.State}
                                errors={formik?.errors?.State}
                                touched={formik?.touched?.State}
                                width="full"
                            />
                            <SelectCountryWithLabel
                                label="Country"
                                name="Country"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Country}
                                errors={formik?.errors?.Country}
                                touched={formik?.touched?.Country}
                                width="full"
                            />

                            <div className="flex items-center justify-end space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium py-5">
                                <div>Upload Authority Confirmation</div>
                                <button className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                    <FaUpload />
                                    <span>Upload</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end">
                        <button
                            className="btn-brown-outline w-44 "
                            type="submit">
                            SUBMIT
                        </button>
                    </div>
                </div>
            </form>

            <Footer />
        </div>
    )
}
