import { useNavigate } from 'react-router-dom'
import { Footer } from '../../../../components/footer/footer'
import { Nav } from '../../../../components/nav/nav'
import { Header } from '../../../../components/header/header'

import { FaArrowLeft } from 'react-icons/fa'
import { TableHead } from '../../../../components/table/head'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getWarrantDetail, prepareWarrantRequest } from '../../../../redux/actions/auth.actions'
import { RootState } from '../../../../redux/rootstore'
import { useFormik } from 'formik'
import * as Yup from 'yup'


export const ExerciseInstruction = () => {
    const navigate = useNavigate()
    const { warrant_detail, user, warrant_request, event, econsentAddedd } = useSelector((state: RootState) => state && state.VerificationReducer)
    const [warrants_to_be_exercised, setWarrantToExcercise] = useState(warrant_request.warrants_to_be_exercised);
    const [residency_holder_status, setResidencyHolder] = useState(warrant_request.residency_holder_status);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(getWarrantDetail(navigate))
    }, [])

    const formik = useFormik({
        initialValues: {
            warrants_to_be_exercised: warrant_request.warrants_to_be_exercised,
            residency_holder_status: warrant_request.residency_holder_status
        },
        validationSchema: Yup.object({
            warrants_to_be_exercised: Yup.number().min(0).max(warrant_detail.warrants_available_for_exercise).required("Input Warrants to be Exercised"),
            residency_holder_status: warrant_detail.ask_residency_holder_status_question ? Yup.string().required() : Yup.string()
        }),
        onSubmit: (values) => {
            let { warrants_to_be_exercised, residency_holder_status } = values
            let total_amount_payable = warrants_to_be_exercised * warrant_detail.payment_rate
            let payload: any = { warrants_to_be_exercised, total_amount_payable }
            if (warrant_detail.ask_residency_holder_status_question) {
                payload.residency_holder_status = residency_holder_status
                dispatch(prepareWarrantRequest(payload))
                if(user?.event_detail?.is_transfer_allowed){
                    navigate('/registration-instruction-step-1')
                }else {
                    if(user?.event_detail?.allow_deliver_to_alternate_mailing_address || user?.event_detail?.allow_hold_for_pickup){
                        navigate('/delivery-instruction')
                    } else {
                        navigate('/warrant-payment')
                    }
                }
            } else {
                dispatch(prepareWarrantRequest(payload))
                if(user?.event_detail?.is_transfer_allowed){
                    navigate('/registration-instruction-step-1')
                }else {
                    if(user?.event_detail?.allow_deliver_to_alternate_mailing_address || user?.event_detail?.allow_hold_for_pickup){
                        navigate('/delivery-instruction')
                    } else {
                        navigate('/warrant-payment')
                    }
                }
            }
        }
    })

    return (
        <div className="w-full flex flex-col items-center min-h-screen">
            <Nav enableProps={true} />
            <Header
                heading={event.event_name}
                description="Exercise Instructions"
            />

            <div className="page-padding space-y-8 py-10">
                <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                    <FaArrowLeft /> <span>BACK</span>
                </div>
                <div className="md:px-14 flex flex-col items-center justify-center space-y-8">
                    <div className="text-sm ">
                        The holder of the Warrants evidenced below, hereby
                        exercises the right to acquire the securities named
                        below, in accordance with the terms and conditions of
                        the Warrants (as previously acknowledged agreed to in a
                        previous step):
                    </div>
                    <div className="w-full">
                        <div className="border space-y-4 border-blue-base p-8  text-sm 2xl:text-base Roboto-Regular w-full">
                            <div className="flex space-x-2 items-end">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                                    Warrants:
                                </div>
                                <div>{warrant_detail.current_issue_name} {warrant_detail.current_issue_type}</div>
                            </div>
                            <div className="flex space-x-2 items-end">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                                    Registered in the Name of:
                                </div>
                                <div>{warrant_detail.holder_reg1 && warrant_detail.holder_reg1.substring(0, 120)}</div>
                            </div>
                            {/* <div className="flex space-x-2 items-end">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                                    Account Number:
                                </div>
                                <div>{warrant_detail.account_number && warrant_detail.account_number.substring(0, 10)}</div>
                            </div> */}
                            <div className="flex space-x-2 items-end">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                                    To be Exercised for:
                                </div>
                                <div>{warrant_detail.new_issue_name} {warrant_detail.new_issue_type}</div>
                            </div>
                            <div className="flex space-x-2 items-end">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                                    Current Exercise Terms:
                                </div>
                                <div>
                                    {warrant_detail.exercise_terms}
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="border-collapse border border-blue-normal w-full">
                        <TableHead
                            headings={[
                                'TOTAL ORIGINAL WARRANTS',
                                'WARRANTS PREVIOUSLY EXERCISED',
                                'WARRANTS AVAILABLE FOR EXERCISE',
                            ]}
                        />
                        <tbody className="Roboto-Medium">
                            <tr className={`text-center h-10 `}>
                                <td className="border border-blue-normal">
                                    {warrant_detail.total_original_warrants}
                                </td>
                                <td className="border border-blue-normal">
                                    {warrant_detail.warrants_previously_exercised}
                                </td>
                                <td className="border border-blue-normal">
                                    {warrant_detail.warrants_available_for_exercise}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form  className="w-full" onSubmit={formik.handleSubmit}>
                        <div className="flex items-center w-full h-10 border border-blue-base mb-5">
                            <div className="w-1/2 text-center bg-blue-normal text-white h-full py-2 px-5">
                                NUMBER OF WARRANTS TO BE EXERCISED
                            </div>
                            <div className="w-1/2 h-full">
                                <input
                                    name="warrants_to_be_exercised"
                                    className="px-5 w-full h-full"
                                    type="text"
                                    placeholder="Please enter the number of Warrants you wish to exercise"
                                    value={formik?.values?.warrants_to_be_exercised}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                            {formik?.errors?.warrants_to_be_exercised && (
                                <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                                    {formik?.errors?.warrants_to_be_exercised}
                                </div>
                            )}
                        {warrant_detail.ask_residency_holder_status_question &&
                            <div className="flex flex-col md:flex-row items-center w-full bg-blue-normal border border-blue-base mb-5">
                                <div className="md:w-1/2 text-center bg-blue-normal text-white h-full py-2 px-5">
                                    RESIDENCY/HOLDER STATUS
                                </div>
                                <div className="md:w-1/2 flex flex-col">
                                    <div className="h-10 px-5 flex items-center bg-white border-b border-blue-base">
                                        <div className="flex space-x-2  md:space-x-2 items-center cursor-pointer">
                                            <input
                                                type="radio"
                                                name="residency_holder_status"
                                                id="residencyholder1"
                                                checked={formik.values.residency_holder_status === "a"}
                                                value={'a'}
                                                onChange={formik.handleChange}
                                                className="cursor-pointer accent-[#0E294B]  outline-none ring-0 focus:ring-0 "
                                            />
                                            <label
                                                className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mt-0.5"
                                                htmlFor="">
                                                <div>
                                                    (A) not a US Person or in the United
                                                    States
                                                </div>
                                            </label>
                                        </div>
                                    </div>{' '}
                                    <div className="h-10 px-5 flex items-center bg-white border-b border-blue-base">
                                        <div className="flex space-x-2  md:space-x-2 items-center cursor-pointer">
                                            <input
                                                type="radio"
                                                name="residency_holder_status"
                                                id="residencyholder2"
                                                value={'b'}
                                                checked={formik.values.residency_holder_status === "b"}
                                                onChange={formik.handleChange}
                                                className="cursor-pointer accent-[#0E294B]  outline-none ring-0 focus:ring-0 "
                                            />
                                            <label
                                                className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mt-0.5"
                                                htmlFor="">
                                                <div>(B) Original US Warrantholder</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="h-10 px-5 flex items-center bg-white ">
                                        <div className="flex space-x-2  md:space-x-2 items-center cursor-pointer">
                                            <input
                                                type="radio"
                                                name="residency_holder_status"
                                                id="residencyholder3"
                                                value={'c'}
                                                checked={formik.values.residency_holder_status === "c"}
                                                onChange={formik.handleChange}
                                                className="cursor-pointer accent-[#0E294B]  outline-none ring-0 focus:ring-0 "
                                            />
                                            <label
                                                className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mt-0.5"
                                                htmlFor="">
                                                <div>
                                                    (C) is a US Person or in the United
                                                    States, with an exemption from
                                                    registration requirements
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>}
                            {formik?.errors?.residency_holder_status && (
                                <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                                    {formik?.errors?.residency_holder_status}
                                </div>
                            )}

                        <div className="flex w-full justify-end">
                            <div className="md:w-1/2 flex flex-col items-end space-y-8">
                            {user && user.event_detail && user.event_detail.offer_econsent && 
                                <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row items-center md:space-x-10 ">
                                    <div className="Roboto-Medium">
                                        Consent to E-Delivery: { (user.securityholder_detail && user.securityholder_detail.email) ||econsentAddedd ? 'YES' : 'NO'}
                                    </div>
                                    {(user.securityholder_detail && user.securityholder_detail.email) ||econsentAddedd ? '' :<button className="btn-blue-outline whitespace-nowrap w-44"
                                        onClick={() => navigate('/econsent-email')}>
                                        PROVIDE ECONSENT
                                    </button>}
                                </div>}
                                {formik.values.warrants_to_be_exercised && <button
                                    type="submit"
                                    className="btn-brown  whitespace-nowrap w-44">
                                    ISSUE ENTITLEMENT(S)
                                </button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}
