import uuid from 'react-uuid'
import {ADMIN_ENDPOINTS, AUTH_ENDPOINTS} from '../../utils/api/api'
import {setAppLoadingState} from './appLoading.action'
import {
    setLocalStorage,
    removeLocalStorage,
    showToast,
    getLocalStorage,
    handleUpload,
} from '../../utils/functions'
import {
    TOAST_TYPE,
    TOKEN_KEY,
    EVENT_ID,
    SESSION_ID,
} from '../../utils/constants/'
import http from '../../utils/api/http'
import {ADMIN_AUTH_ACTION_TYPES, AUTH_ACTION_TYPES} from '../types'

//reset authentication redux state
export const resetAuthState = () => {
    return (dispatch) => {
        return dispatch({
            type: AUTH_ACTION_TYPES.RESET_AUTH_STATE,
        })
    }
}

//admin login user
export const onAdminLogin = (payload, navigate) => {
    return async (dispatch) => {
        console.log('payload', payload)
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(ADMIN_ENDPOINTS.LOGIN, payload)
            if (response.status !== 200 || !response.data) {
                console.warn('response.status', response.status)
                showToast('Invalid email/password', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_AUTH_ACTION_TYPES.AUTH_FAILED,
                })
            }

            //set local storage
            setLocalStorage(TOKEN_KEY, response.data?.data?.token)
            dispatch(setAppLoadingState(false))
            dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.LOGIN,
                payload: response.data.data,
            })
            if (response?.data?.data?.is2fa) {
                return true
            } else {
                showToast('Login Success', TOAST_TYPE.success)
                navigate('/admin-clients')
                return false
            }
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.AUTH_FAILED,
            })
        }
    }
}

//admin SendOTP
export const onAdminSendOTP = (payload, handleOpenToken, isLoginFlow) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(ADMIN_ENDPOINTS.SEND_OTP, payload)
            if (response.status !== 200 || !response.data) {
                console.warn('response.status', response.status)
                showToast('Something went wrong!', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_AUTH_ACTION_TYPES.SEND_OTP,
                })
            }
            showToast('Code has been sent', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            !isLoginFlow && handleOpenToken && handleOpenToken()
            return dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.SEND_OTP,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.SEND_OTP_FAILED,
            })
        }
    }
}

//admin ConfirmOTP
export const onAdminConfirmOTP = (payload, handleOpenPassword, isLoginFlow) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                isLoginFlow
                    ? ADMIN_ENDPOINTS.CONFIRM_2FA_OTP
                    : ADMIN_ENDPOINTS.CONFIRM_OTP,
                payload
            )
            if (response.status !== 200 || !response.data) {
                showToast('Invalid code', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_AUTH_ACTION_TYPES.CONFIRM_OTP,
                })
            }

            //set local storage
            console.log('response.data', response.data)
            isLoginFlow && setLocalStorage(TOKEN_KEY, response.data?.token)
            handleOpenPassword(response.data)
            showToast('Code has been verified!', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.CONFIRM_OTP,
                payload: isLoginFlow ? response.data : response.data.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.CONFIRM_OTP_FAILED,
            })
        }
    }
}

//admin ConfirmOTP
export const onResetPassword = (payload, handleOpenPassword) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                ADMIN_ENDPOINTS.RESET_PASSWORD,
                payload
            )
            if (response.status !== 200 || !response.data) {
                console.warn('response.status', response.status)
                showToast(
                    response.data?.message || 'Something wrong went!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_AUTH_ACTION_TYPES.RESET_PASSWORD,
                })
            }

            //set local storage
            // setLocalStorage(TOKEN_KEY, response.data?.data?.token)
            handleOpenPassword && handleOpenPassword()
            showToast('Password has been changed!', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.RESET_PASSWORD,
                payload: response.data.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.RESET_PASSWORD_FAILED,
            })
        }
    }
}

//login user
export const onLogin = (payload, navigate) => {
    return async (dispatch) => {
        // const navigate = useNavigate()
        try {
            dispatch(setAppLoadingState(true))
            let identifier = uuid()
            const formdata = new FormData()
            formdata.append('ofac', Number(payload.accountNumber))
            formdata.append('pin', Number(payload.accountPin))
            formdata.append('event_id', payload.eventId)
            formdata.append('session_id', identifier)
            const response = await http.post(AUTH_ENDPOINTS.LOGIN, formdata)
            if (response.status !== 200 || !response.data) {
                dispatch(setAppLoadingState(false))
                if (
                    response.status === 403 &&
                    response?.data?.isEnded === 'True'
                ) {
                    return response?.data
                } else {
                    showToast('Invalid credentials', TOAST_TYPE.error)

                    return dispatch({
                        type: AUTH_ACTION_TYPES.AUTH_FAILED,
                    })
                }
            }

            //set local storage
            setLocalStorage(TOKEN_KEY, response.data?.data?.token)
            setLocalStorage(EVENT_ID, payload.eventId)
            setLocalStorage(SESSION_ID, identifier)
            showToast('Login success', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            dispatch({
                type: AUTH_ACTION_TYPES.LOGIN,
                payload: response.data.data,
            })
            if (
                response?.data?.data?.event_detail
                    ?.term_and_conditions_of_portal
            ) {
                navigate('/terms-conditions')
            } else {
                navigate('/event-term-conditions')
            }
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.AUTH_FAILED,
            })
        }
    }
}

export const onHome = (eventId, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(`${AUTH_ENDPOINTS.HOME}/${eventId}`)
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    eventLogout(navigate)
                }
                showToast('Error getting event detail', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: AUTH_ACTION_TYPES.HOME,
                    payload: {},
                })
            }
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.HOME,
                payload: response.data.data,
            })
        } catch (err) {
            return dispatch(setAppLoadingState(false))
        }
    }
}

export const getEventTermAndConditions = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))

            const response = await http.get(
                AUTH_ENDPOINTS.EVENT_TERMS_AND_CONDITIONS
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    eventLogout(navigate)
                }
                showToast(
                    'Error Getting event term and conditions',
                    TOAST_TYPE.error
                )
                return dispatch(setAppLoadingState(false))
            }
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.EVENT_TERM_AND_CONDITIONS,
                payload: response.data.data,
            })
        } catch (err) {
            showToast(
                'Error Getting event term and conditions',
                TOAST_TYPE.error
            )
            dispatch(setAppLoadingState(false))
        }
    }
}
export const onEconsentEmail = (payload, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const formdata = new FormData()
            formdata.append('email', payload?.Email)
            const response = await http.post(AUTH_ENDPOINTS.EMAIL, formdata)
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    eventLogout(navigate)
                }
                showToast('Error adding email', TOAST_TYPE.error)
                return dispatch(setAppLoadingState(false))
            }
            dispatch({type: AUTH_ACTION_TYPES.ECONSENT_ADDED})
            return dispatch(setAppLoadingState(false))
        } catch (err) {
            return dispatch(setAppLoadingState(false))
        }
    }
}
//delete user session
export const onAdminLogoutUser = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const logout = await http.post(ADMIN_ENDPOINTS.LOGOUT)

            if (logout.status !== 200 || !logout.data) {
                showToast('Unable to process your request', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_AUTH_ACTION_TYPES.AUTH_FAILED,
                })
            }

            //remove token from local storage
            removeLocalStorage(TOKEN_KEY)
            navigate('/admin-login')
            showToast('Logout success', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.LOGOUT,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_AUTH_ACTION_TYPES.AUTH_FAILED,
            })
        }
    }
}

export const updatePortalTermAndConditionStatus = (val, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                AUTH_ENDPOINTS.PORTAL_TERM_CONDITIONS,
                {
                    mark_read_term_and_conditions_of_portal: val,
                }
            )
            showToast(
                'Portal terms and conditions accepted',
                TOAST_TYPE.success
            )
            dispatch(setAppLoadingState(false))
            dispatch({
                type: AUTH_ACTION_TYPES.UPDATE_PORTAL_TERM_AND_CONDITIONS,
                payload: val,
            })
            if (response.status === 401) {
                eventLogout(navigate)
            }
            navigate('/event-term-conditions')
        } catch (err) {
            showToast(
                'Error Updating portal term and conditions',
                TOAST_TYPE.error
            )
            dispatch(setAppLoadingState(false))
        }
    }
}

export const updateEventTermAndConditionStatus = (val, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                AUTH_ENDPOINTS.EVENT_TERMS_AND_CONDITIONS,
                {
                    mark_read_term_and_conditions_of_event: val,
                }
            )
            showToast('Event term and conditions updated', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            if (response.status === 401) {
                eventLogout(navigate)
            }
        } catch (err) {
            showToast(
                'Error Updating event term and conditions',
                TOAST_TYPE.error
            )
            dispatch(setAppLoadingState(false))
        }
    }
}
export const checkSecuritiesHold = (val, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                AUTH_ENDPOINTS.CHECK_SECURITIES_HOLD,
                {total_securities: val}
            )
            dispatch(setAppLoadingState(false))
            if (response.status === 401) {
                eventLogout(navigate)
            }
            if (response.status == 400) {
                return false
            } else if (response.status == 200) {
                return true
            } else {
                return false
            }
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return false
        }
    }
}

export const setConsolidationExchangeDetail = (payload) => {
    return async (dispatch) => {
        return dispatch({
            type: AUTH_ACTION_TYPES.GET_CONSOLIDATION_EXCHANGE_DETAIL,
            payload: payload,
        })
    }
}
export const getConsolidationExchangeDetail = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                AUTH_ENDPOINTS.CONSOLIDATION_EXCHANGE_DETAIL
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    eventLogout(navigate)
                }
                showToast('Not able to get event information', TOAST_TYPE.error)
                return dispatch(setAppLoadingState(false))
            }
            dispatch(setAppLoadingState(false))
            return dispatch(setConsolidationExchangeDetail(response.data.data))
        } catch (err) {
            return dispatch(setAppLoadingState(false))
        }
    }
}
export const postConsolidationExchangeDetail = (input, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const session_id = getLocalStorage(SESSION_ID)
            const response = await http.post(
                AUTH_ENDPOINTS.POST_CONSOLIDATION_EVENT,
                {...input, session_id}
            )
            if (response.status !== 201 || !response.data) {
                if (response.status === 401) {
                    eventLogout(navigate)
                }
                if (response.status === 400) {
                    showToast(
                        response.data?.non_field_errors?.[0],
                        TOAST_TYPE.error
                    )
                } else {
                    showToast(
                        'Error posting consolidation event',
                        TOAST_TYPE.error
                    )
                }
                return dispatch(setAppLoadingState(false))
            }
            dispatch(setAppLoadingState(false))
            showToast(
                'Event has been submitted sucessfully',
                TOAST_TYPE.success
            )
            // dispatch({
            //     type: AUTH_ACTION_TYPES.RESET_CONSOLIDATION_EXCHANGE_DETAIL
            // })
            navigate('/confirm-download')
        } catch (err) {
            return dispatch(setAppLoadingState(false))
        }
    }
}
export const prepareConsolidationExchangeDetail = (input) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: AUTH_ACTION_TYPES.PREPARE_CONSOLIDATION_EXCHANGE_DETAIL,
                payload: input,
            })
        } catch (err) {}
    }
}

export const getWarrantDetail = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(AUTH_ENDPOINTS.GET_WARRANT_DETAIL)
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    eventLogout(navigate)
                }
                return dispatch(setAppLoadingState(false))
            }
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.GET_WARRANT_DETAIL,
                payload: response.data.data,
            })
        } catch (err) {
            return dispatch(setAppLoadingState(false))
        }
    }
}
export const postWarrantDetail = (input, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const session_id = getLocalStorage(SESSION_ID)
            const response = await http.post(
                AUTH_ENDPOINTS.POST_WARRANT_EVENT,
                {...input, session_id}
            )
            if (response.status !== 201 || !response.data) {
                if (response.status === 401) {
                    eventLogout(navigate)
                }
                if (response.status === 400) {
                    showToast(
                        response.data?.non_field_errors?.[0],
                        TOAST_TYPE.error
                    )
                } else {
                    showToast('Error posting warrant event', TOAST_TYPE.error)
                }
                return dispatch(setAppLoadingState(false))
            }
            dispatch(setAppLoadingState(false))
            showToast(
                'Event has been submitted sucessfully',
                TOAST_TYPE.success
            )
            // dispatch({
            //     type: AUTH_ACTION_TYPES.RESET_WARRANT_DETAIL
            // })
            navigate('/confirm-download')
        } catch (err) {
            return dispatch(setAppLoadingState(false))
        }
    }
}

export const prepareWarrantRequest = (input) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: AUTH_ACTION_TYPES.PREPARE_WARRANT_REQUEST,
                payload: input,
            })
        } catch (err) {}
    }
}

export const getPlanOfArrangementDetail = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                AUTH_ENDPOINTS.GET_PLAN_OF_ARRANGEMENT_DETAIL
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    eventLogout(navigate)
                }
                showToast('Not able to get event information', TOAST_TYPE.error)
                return dispatch(setAppLoadingState(false))
            }
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.GET_CONSOLIDATION_EXCHANGE_DETAIL,
                payload: response && response.data && response.data.data,
            })
        } catch (err) {
            return dispatch(setAppLoadingState(false))
        }
    }
}

export const postPlanOfArrangementDetail = (input, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const session_id = getLocalStorage(SESSION_ID)
            const response = await http.post(
                AUTH_ENDPOINTS.POST_PLAN_OF_ARRANGEMENT_EVENT,
                {...input, session_id}
            )
            if (response.status !== 201 || !response.data) {
                if (response.status === 401) {
                    eventLogout(navigate)
                }
                if (response.status === 400) {
                    showToast(
                        response.data?.non_field_errors?.[0],
                        TOAST_TYPE.error
                    )
                } else {
                    showToast(
                        'Error posting plan of arrangement event',
                        TOAST_TYPE.error
                    )
                }
                return dispatch(setAppLoadingState(false))
            }
            dispatch(setAppLoadingState(false))
            showToast(
                'Event has been submitted sucessfully',
                TOAST_TYPE.success
            )
            // dispatch({
            //     type: AUTH_ACTION_TYPES.RESET_CONSOLIDATION_EXCHANGE_DETAIL
            // })
            navigate('/confirm-download')
        } catch (err) {
            return dispatch(setAppLoadingState(false))
        }
    }
}
export const eventLogout = (navigate) => {
    removeLocalStorage(TOKEN_KEY)
    const eventId = getLocalStorage(EVENT_ID)
    navigate(`/event/${eventId}`)
}

//Not Used Actions

//login user
export const onTermCondition = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const termAndConditionData = await http.get(
                AUTH_ENDPOINTS.TERM_CONDITIONS
            )
            //check for response
            if (
                termAndConditionData.status !== 200 ||
                !termAndConditionData.data
            ) {
                if (termAndConditionData.status === 401) {
                    eventLogout(navigate)
                }
                showToast('Error Getting term and conditions', TOAST_TYPE.error)
                return dispatch(setAppLoadingState(false))
            }

            //set local storage
            dispatch(setAppLoadingState(false))
            navigate('/terms-conditions')
            return dispatch({
                type: AUTH_ACTION_TYPES.EVENT_TERM_AND_CONDITIONS,
                payload: termAndConditionData.data,
            })
        } catch (err) {
            showToast('Error Getting term and conditions', TOAST_TYPE.error)
            return dispatch(setAppLoadingState(false))
        }
    }
}

//create new account!
export const onRegister = (payload, navigate) => {
    return async (dispatch) => {
        try {
            if (payload?.email.toLocaleLowerCase().match(/admin/g)) {
                return showToast('You cannot use this email', TOAST_TYPE.error)
            }

            dispatch(setAppLoadingState(true))
            console.log(payload)
            const registerUser = await http.post(AUTH_ENDPOINTS.SIGNUP, payload)
            console.log(registerUser)
            //check for response
            if (registerUser.status !== 200 || !registerUser?.data) {
                showToast('Unable to create account!', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: AUTH_ACTION_TYPES.AUTH_FAILED,
                })
            }

            //set local storage
            setLocalStorage(TOKEN_KEY, registerUser.data?.token)

            showToast('Register success', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            navigate('/dashboard')
            return dispatch({
                type: AUTH_ACTION_TYPES.REGISTER,
                payload: registerUser.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.AUTH_FAILED,
            })
        }
    }
}

export const onChangePassword = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const userInfo = await http.put(
                AUTH_ENDPOINTS.CHANGE_PASSWORD,
                payload
            )
            console.log(userInfo)
            if (userInfo.status !== 200 || !userInfo.data) {
                showToast('Invalid userInfo', TOAST_TYPE.error)
                return dispatch(setAppLoadingState(false))
            }
            showToast('userInfo updated success', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            console.log(userInfo, 'test')
            // return dispatch({
            //     type: AUTH_ACTION_TYPES.UPDATE,
            //     payload: userInfo.data,
            // })
        } catch (err) {
            dispatch(setAppLoadingState(false))
        }
    }
}

export const onUpdateCompanyInfo = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const companyInfo = await http.put(AUTH_ENDPOINTS.UPDATE, payload)
            console.log(companyInfo)
            if (companyInfo.status !== 200 || !companyInfo.data) {
                showToast('Invalid companyInfo', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
            }
            showToast('companyInfo updated success', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            console.log(companyInfo, 'test')
            return dispatch({
                type: AUTH_ACTION_TYPES.UPDATE,
                payload: companyInfo.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
        }
    }
}

export const onUpdateProfile = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const user = await http.put(AUTH_ENDPOINTS.UPDATE_PROFILE, payload)
            console.log(user)
            if (user.status !== 200 || !user.data) {
                showToast('Invalid user data', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
            }
            console.log(user.data)
            showToast('user updated success', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.UPDATE,
                payload: user.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            console.log(err)
        }
    }
}

//delete user session
export const onLogoutUser = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const logout = await http.post(AUTH_ENDPOINTS.LOGOUT)

            if (logout.status !== 200 || !logout.data) {
                showToast('Unable to process your request', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: AUTH_ACTION_TYPES.AUTH_FAILED,
                })
            }

            //remove token from local storage
            eventLogout(navigate)
            showToast('Logout success', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.LOGOUT,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.AUTH_FAILED,
            })
        }
    }
}

//get the logged-in user details from server
export const getSessionUserDetails = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(setAppLoadingState(true))
            const sessionUser = await http.post(AUTH_ENDPOINTS.SESSION_USER)

            dispatch(setAppLoadingState(false))
            return dispatch({
                type: AUTH_ACTION_TYPES.LOGIN,
                payload: sessionUser.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            removeLocalStorage(TOKEN_KEY)
            return dispatch({
                type: AUTH_ACTION_TYPES.RESET_AUTH_STATE,
            })
        }
    }
}

export const resetEvents = () => {
    return async (dispatch, getState) => {
        try {
            await dispatch({
                type: AUTH_ACTION_TYPES.RESET_CONSOLIDATION_EXCHANGE_DETAIL,
            })
            await dispatch({
                type: AUTH_ACTION_TYPES.RESET_WARRANT_DETAIL,
            })
            await dispatch({
                type: AUTH_ACTION_TYPES.RESET_CONSOLIDATION_EXCHANGE_DETAIL,
            })
        } catch (err) {}
    }
}

export const handleUploadDoc = (file) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(setAppLoadingState(true))
            let response = await handleUpload(file)
            await dispatch(setAppLoadingState(false))
            return response
        } catch (err) {
            dispatch(setAppLoadingState(false))
        }
    }
}
