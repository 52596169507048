
import {FC, useState} from 'react'
import {
    FaBars,
    FaEnvelope,
    FaKey,
    FaLock,
    FaShieldAlt,
    FaStickyNote,
} from 'react-icons/fa'
import {Link, useNavigate} from 'react-router-dom'
import {imgs} from '../../assets/images/img'
import {MuiDrawer} from '../drawer'
import Switch from "react-switch";
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/rootstore'
import { useDispatch } from 'react-redux'
import { set2FA } from '../../redux/actions/client.actions'

type NavProps = {
    onLogout?: () => void
}
export const NavLogged: FC<NavProps> = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const [drawerState, setdrawerState] = useState(false)
    const client_details = useSelector((state:RootState)=>state && state.ClientReducer && state.ClientReducer.client_detail)
    const [enableSFA, setenableSFA] = useState(client_details && client_details.is2fa || false)

    const onChange = async (checked: any) => {
        setenableSFA(checked)
        dispatch(set2FA({is2fa:checked}, navigate, false))
    }
    const DrawerContent = (
        <div className="py-5 space-y-8 text-xs md:text-sm lg:text-base Poppins-Medium bg-gray-dashboard ">
            <div className="flex items-center space-x-4 text-blue-base px-10">
                <FaBars
                    className="text-xl Roboto-Bold cursor-pointer"
                    onClick={() => setdrawerState(!drawerState)}
                />
                <div className="flex flex-col items-center">
                    <img
                        src={imgs.logo}
                        alt=""
                        className="h-6 w-full sm:h-10 object-cover"
                    />
                    <div className="self-end Roboto-Medium text-xs sm:text-sm ">
                        XPRESS
                    </div>
                </div>
            </div>
            <div className="px-10 space-y-0">
                <div className=" text-brown text-lg Roboto-Medium">
                    {client_details.client_company_name}
                </div>
                <div className="flex space-x-4 text-xs md:text-sm Roboto-Medium items-center  text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                    <FaEnvelope />
                    <span>{client_details.email}</span>
                </div>
                <div className='flex items-center justify-between'>
                    <span className='text-xs text-blue-base Roboto-Medium'>ENABLE 2FA</span>
                    <Switch
                        borderRadius={11}
                        onColor={'#B4873F'}
                        // offColor="red"
                        checked={enableSFA}
                        onChange={(e) => onChange(e)}
                    />
                </div>
            </div>
           <div>
                {/* <Link
                to="/user/event/securityholder"
                onClick={() => setdrawerState(!drawerState)}
                className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                <FaKey />
                <span>SecurityHolders</span>
            </Link> */}
            <Link
                to="/user/update-password"
                onClick={() => setdrawerState(!drawerState)}
                className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                <FaLock />
                <span>Change Password</span>
            </Link>
            <Link
                to="/user/terms-and-conditions"
                onClick={() => setdrawerState(!drawerState)}
                className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                <FaStickyNote />
                <span>Terms and Conditions</span>
            </Link>
            <Link
                to="/user/privacy-policy"
                onClick={() => setdrawerState(!drawerState)}
                className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                <FaShieldAlt />
                <span>Privacy Policy</span>
            </Link>
            <div
                onClick={props.onLogout}
                className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                <FaShieldAlt />
                <span>Logout</span>
            </div>
           </div>
        </div>
    )
    return (
        <div className="w-full flex h-24 items-center justify-between text-blue-base bg-white py-1 px-5 sm:px-8 border-b-2 border-blue-base">
            <div className="flex space-x-6 items-center">
                <FaBars
                    className="text-xl cursor-pointer md:text-2xl lg:text-3xl Roboto-Bold"
                    onClick={() => setdrawerState(!drawerState)}
                />
                <div className="flex flex-col items-center">
                    <img
                        src={imgs.logo}
                        alt=""
                        className="h-6 w-full sm:h-10 object-cover"
                    />
                    <div className="self-end Roboto-Medium text-xs sm:text-sm ">
                        XPRESS
                    </div>
                </div>
            </div>
            <div className="Roboto-Bold text-xl lg:text-2xl hidden lg:block">
                CORPORATE EVENT GATEWAY
            </div>

            <div className="flex items-center justify-between space-x-0 md:space-x-8 ">
                <div className="Roboto-Bold text-sm sm:text-base md:text-lg hidden lg:block">
                    {client_details.logo && <img src={client_details.logo} alt=""
                        className="h-6 w-full sm:h-10 object-cover"
                    />}
                </div>
                <div className="cursor-pointer Roboto-Medium px-5 py-1 bg-blue-base text-white text-sm sm:text-base md:text-lg"
                onClick={()=>navigate('/user/home')}>
                    HOME
                </div>
            </div>
            <MuiDrawer
                drawerState={drawerState}
                onToggleDrawer={() => setdrawerState(!drawerState)}
                content={DrawerContent}
            />
        </div>
    )
}
