import { FC } from 'react'

type checkboxProps = {
    text: any
    name: string
    onChange: any
    onBlur: any
    value: any
    id: string
    stateVal?: any
    type?: string
    disabled?: boolean
}

export const Checkbox: FC<checkboxProps> = (props) => (
    <div className="flex space-x-1  md:space-x-2 md:items-center cursor-pointer">
        <input
            type={props?.type || "radio"}
            {...props}
            className="cursor-pointer accent-[#0E294B]  outline-none ring-0 focus:ring-0 "
            checked={props.stateVal == props.value}
            disabled={props.disabled || false}
        />
        <label
            className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mt-0.5"
            htmlFor={props.id}>
            <div> {props.text}</div>
        </label>
    </div>
)
