import { combineReducers } from 'redux'
import { VerificationReducer } from './reducers/Auth.Reducer'
import { AppLoadingReducer } from './reducers/appLoading.reducer'
import { AdminReducer } from './reducers/Admin.Reducer'
import { ClientReducer } from './reducers/Client.Reducer'
export const rootReducers = combineReducers({
    AppLoadingReducer,
    VerificationReducer,
    AdminReducer,
    ClientReducer
})
export type RootState = ReturnType<typeof rootReducers>

