import { Alert } from '@material-ui/lab'
import {createRef, useEffect, useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {ReactMaterialTable} from '../../../components/table/customtable'
import { getClientEvents } from '../../../redux/actions/client.actions'
import { RootState } from '../../../redux/rootstore'

export const Home = () => {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    const tableRef = createRef();
    const clientEvents = useSelector((state: RootState) => state && state.ClientReducer && state.ClientReducer.client_events)
    const tableData = clientEvents.results || []
    const [statusData, setStatusData] = useState<any>(['pending', 'active', 'completed'])
    
    const [queryParams, setQueryParams] = useState<
        {
            search: string
            status: string
        }[]
    >([])

    // useEffect(()=>{
    //     setStatusData(Array.from(new Set(tableData.map(item=>item.status))))
    // },[tableData])

    var columns = [
        {
            field: 'event_name',
            title: 'EVENT NAME',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.event_name}</div>
                </div>
            ),
        },
        {
            field: 'event_start_date',
            title: 'EVENT DATE',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.event_start_date}</div>
                </div>
            ),
        },
        {
            field: 'total_securities_exchanged_percentage',
            title: 'TOTAL SECURITIES EXCHANGED (%)',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.total_securities_exchanged_percentage.toFixed(2)}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_securities_unexchanged_percentage',
            title: 'TOTAL SECURITIES UNEXCHANGED (%)',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.total_securities_unexchanged_percentage.toFixed(2)}
                    </div>
                </div>
            ),
        },
        {
            field: 'status',
            title: 'EVENT STATUS',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    {rowData.status === 'completed' ? (
                        <div className="font-medium h-8 text-sm flex items-center justify-center w-32 uppercase bg-green-light text-white text-center">
                            {rowData?.status}
                        </div>
                    ) : rowData.status === 'pending' ? (
                        <div className="font-medium h-8 text-sm flex items-center justify-center w-32 uppercase bg-yellow-light text-white text-center">
                            {rowData?.status}
                        </div>
                    ) : (
                        <div className="font-medium h-8 text-sm flex items-center justify-center w-32 uppercase bg-blue-light text-white text-center">
                            {rowData?.status}
                        </div>
                    )}
                </div>
            ),
        },
    ]

    const onEnterInput = (e: any, searchedBy: string) => {
        let query = {...queryParams}
        if(e.target.value){
            query[searchedBy]  = e.target.value
        } else {
            delete query[searchedBy]
        }
        setQueryParams(query)
        tableRef.current && (tableRef.current as any).onQueryChange({page: 0})
    }

    const showEventDetail = (event, rowData) => {
            navigate(`/user/event/${rowData.id}`)
    }

    return (
        <div className="page-padding">
            <div className="flex items-center w-full flex-col md:flex-row md:w-8/12 lg:w-1/2 md:space-x-10">
                <div className="w-full mt-3 h-8 relative flex items-center space-x-3 px-2 md:px-4 bg-white text-gray border  border-bordergray">
                    <input
                        className={`h-full text-gray w-full outline-none px-2 text-xs sm:text-sm 2xl:text-base pace-x-2 sm:space-x-4 2xl:space-x-8 `}
                        onChange={(e: any) =>
                            onEnterInput(e, 'search')
                        }
                        placeholder="Search by event name"
                    />
                    <FaSearch className="text-sm md:text-lg lg:text-xl 2xl:text-2xl bg-white text-home-layout-gray" />
                </div>
                <div
                    className={`w-full mt-3 h-8 relative flex items-center space-x-3 px-2 md:px-4 text-blue-normal  outline-none border border-blue-base Roboto-Medium text-sm`}>
                    <select
                        onChange={(e: any) =>
                            onEnterInput(e, 'status')
                        }
                        className={`h-full outline-none w-full`}>
                         <option value="">-Select status-</option>
                        {statusData.map((item: any, index: any) => (
                            <option key={index} value={item}>
                                {item.toUpperCase()}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="py-10">
            <Alert severity="info">Click on table row to get detail about event</Alert>
                <ReactMaterialTable
                    tableRef = {tableRef}
                    columns={columns}
                    data = {(query)=>
                        new Promise(async (resolve, reject) => {
                            const page = query.page ? query.page + 1 : 1
                            const params = {...queryParams, page}
                            const result = await dispatch(getClientEvents(navigate, params))
                            return resolve(result)
                          })
                    }
                    searchbar={false}
                    toolbar={false}
                    exportbtn={false}
                    fontSize="16px"
                    pagging={true}
                    id="table"
                    rowClickEvent = {(event, rowData)=>showEventDetail(event, rowData)}
                />
            </div>
        </div>
    )
}
