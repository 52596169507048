import {ADMIN_ENDPOINTS} from '../../utils/api/api'
import {setAppLoadingState} from './appLoading.action'
import {removeLocalStorage, showToast} from '../../utils/functions'
import {TOAST_TYPE, TOKEN_KEY} from '../../utils/constants/'
import http from '../../utils/api/http'
import {
    ADMIN_ACTION_TYPES,
    ADMIN_AUTH_ACTION_TYPES,
    AUTH_ACTION_TYPES,
} from '../types'

//reset authentication redux state
export const resetAuthState = () => {
    return (dispatch) => {
        return dispatch({
            type: AUTH_ACTION_TYPES.RESET_AUTH_STATE,
        })
    }
}

//reset authentication redux state
export const resetAdminState = () => {
    return (dispatch) => {
        return dispatch({
            type: ADMIN_ACTION_TYPES.RESET_DETAILS,
        })
    }
}

export const adminLogout = (navigate) => {
    removeLocalStorage(TOKEN_KEY)
    navigate && navigate('/admin-login')
}

//Admin Create Client
export const onCreateClient = (payload, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                ADMIN_ENDPOINTS.ADD_CLIENT,
                payload
            )
            if (
                (response.status !== 200 && response.status !== 201) ||
                !response.data
            ) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    response.data.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.ADD_CLIENT_FAILED,
                })
            }

            showToast('Client has been created!', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.ADD_CLIENT,
                payload: response.data.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.ADD_CLIENT_FAILED,
            })
        }
    }
}

//Admin Create Event
export const onUpdateEvent = (payload, navigate) => {
    return async (dispatch) => {
        console.log('payload', payload)
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.patch(
                ADMIN_ENDPOINTS.UPDATE_EVENT,
                payload
            )
            if (
                (response.status !== 200 && response.status !== 201) ||
                !response.data
            ) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                if (!response?.data?.message) {
                    let message = ''
                    for (const [key, value] of Object.entries(response?.data)) {
                        message = `${key} : ${
                            JSON.stringify(value).includes(
                                'This field may not be blank.'
                            )
                                ? ' is required'
                                : JSON.stringify(value)
                        } `
                    }
                    showToast(
                        message || 'Something Went Wrong!',
                        TOAST_TYPE.error
                    )
                } else {
                    showToast(
                        response?.data?.message || 'Something Went Wrong!',
                        TOAST_TYPE.error
                    )
                }
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.UPDATE_EVENT_FAILED,
                })
            }

            showToast('Event has been updated!', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.UPDATE_EVENT,
                payload: response.data.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.UPDATE_EVENT_FAILED,
            })
        }
    }
}
//Admin Create Event
export const deleteEntitlement = (id, params, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.delete(
                ADMIN_ENDPOINTS.DELETE_EVENT_ENTITLEMENT + id + params,
                {}
            )
            if (
                (response.status !== 200 && response.status !== 201) ||
                !response.data
            ) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                if (!response?.data?.message) {
                    let message = ''
                    for (const [key, value] of Object.entries(response?.data)) {
                        message = `${key} : ${
                            JSON.stringify(value).includes(
                                'This field may not be blank.'
                            )
                                ? ' is required'
                                : JSON.stringify(value)
                        } `
                    }
                    showToast(
                        message || 'Something Went Wrong!',
                        TOAST_TYPE.error
                    )
                } else {
                    showToast(
                        response?.data?.message || 'Something Went Wrong!',
                        TOAST_TYPE.error
                    )
                }
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.DELETE_EVENT_ENTITLEMENT_FAILED,
                })
            }

            showToast('Event entitlement has been deleted!', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.DELETE_EVENT_ENTITLEMENT,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.DELETE_EVENT_ENTITLEMENT_FAILED,
            })
        }
    }
}

//Admin Create Event
export const onCreateEvent = (payload, navigate) => {
    return async (dispatch) => {
        console.log('payload', payload)
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(ADMIN_ENDPOINTS.ADD_EVENT, payload)
            if (
                (response.status !== 200 && response.status !== 201) ||
                !response.data
            ) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                if (!response?.data?.message) {
                    let message = ''
                    for (const [key, value] of Object.entries(response?.data)) {
                        message = `${key} : ${
                            JSON.stringify(value).includes(
                                'This field may not be blank.'
                            )
                                ? ' is required'
                                : JSON.stringify(value)
                        } `
                    }
                    showToast(
                        message || 'Something Went Wrong!',
                        TOAST_TYPE.error
                    )
                } else {
                    showToast(
                        response?.data?.message || 'Something Went Wrong!',
                        TOAST_TYPE.error
                    )
                }
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.ADD_EVENT_FAILED,
                })
            }

            showToast('Event has been created!', TOAST_TYPE.success)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.ADD_EVENT,
                payload: response.data.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.ADD_EVENT_FAILED,
            })
        }
    }
}

//Admin Get Client
export const onGetClient = (params, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(ADMIN_ENDPOINTS.GET_CLIENT, {
                params,
            })
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                console.warn('response.status', response.status)
                showToast('Something Went Wrong!', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.GET_CLIENT_FAILED,
                })
            }
            console.log('response.data', response.data)
            dispatch(setAppLoadingState(false))
            dispatch({
                type: ADMIN_ACTION_TYPES.GET_CLIENT,
                payload: response.data,
            })
            return {
                data: response?.data?.results,
                page: params && params.page ? params.page - 1 : 0,
                totalCount: response?.data?.count,
            }
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.GET_CLIENT_FAILED,
            })
        }
    }
}

//Admin Get Client
export const onGetEventType = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(ADMIN_ENDPOINTS.GET_EVENT_TYPE)
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                console.warn('response.status', response.status)
                showToast('Something Went Wrong!', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.GET_EVENT_TYPE_FAILED,
                })
            }
            console.log('response.data', response.data)
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.GET_EVENT_TYPE,
                payload: response.data?.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.GET_EVENT_TYPE_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onGetEvents = (params, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(ADMIN_ENDPOINTS.GET_EVENTS, {
                params,
            })
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast('Something Went Wrong!', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.GET_EVENTS_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            dispatch({
                type: ADMIN_ACTION_TYPES.GET_EVENTS,
                payload: response.data,
            })
            return {
                data: response?.data?.results,
                page: params && params.page ? params.page - 1 : 0,
                totalCount: response?.data?.count,
            }
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.GET_EVENTS_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onGetEventDetailsOnly = (query, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                ADMIN_ENDPOINTS.GET_EVENT_DETAILS + query
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast('Something Went Wrong!', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.GET_EVENT_DETAILS_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.GET_EVENT_DETAILS,
                payload: response.data.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.GET_EVENT_DETAILS_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onGetEventDetails = (
    query,
    query1,
    params,
    navigate,
    isReturn
) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                ADMIN_ENDPOINTS.GET_EVENT_DETAILS + query
            )
            const responseHolder = await http.get(
                ADMIN_ENDPOINTS.GET_EVENT_DETAIL_HOLDER + query1,
                {params}
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast('Something Went Wrong!', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.GET_EVENT_DETAILS_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            if (!isReturn) {
                dispatch({
                    type: ADMIN_ACTION_TYPES.GET_EVENT_DETAILS,
                    payload: response.data.data,
                    eventHolder: responseHolder.data,
                })
            }
            return {
                data: responseHolder?.data?.results,
                page: params && params.page ? params.page - 1 : 0,
                totalCount: responseHolder?.data?.count,
                eventDetails: response.data.data,
            }
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.GET_EVENT_DETAILS_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onGenerateAndGetAccessCodes = (query, body, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                ADMIN_ENDPOINTS.GENERATE_AND_GET_ACCESS_CODES + query,
                body
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    response?.data?.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.GENERATE_AND_GET_ACCESS_CODES_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            showToast('Access codes has been generated!', TOAST_TYPE.success)
            return dispatch({
                type: ADMIN_ACTION_TYPES.GENERATE_AND_GET_ACCESS_CODES,
                payload: response.data.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.GENERATE_AND_GET_ACCESS_CODES_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onGetGenerateAndGetAccessCodes = (query, params, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                ADMIN_ENDPOINTS.GENERATE_AND_GET_ACCESS_CODES + query,
                {params}
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    response?.data?.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.GET_GENERATE_AND_GET_ACCESS_CODES_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            dispatch({
                type: ADMIN_ACTION_TYPES.GET_GENERATE_AND_GET_ACCESS_CODES,
                payload: response.data,
            })
            return {
                data: response?.data?.results,
                page: params && params.page ? params.page - 1 : 0,
                totalCount: response?.data?.count,
            }
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.GET_GENERATE_AND_GET_ACCESS_CODES_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onSendAccessCode = (body, noAlert, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                ADMIN_ENDPOINTS.SEND_ACCESS_CODES,
                body
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    response?.data?.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(true))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.SEND_ACCESS_CODES_FAILED,
                })
            }

            dispatch(onGetAccessCodes(body?.event_id))
            dispatch(setAppLoadingState(false))
            !noAlert &&
                showToast('Access code has been sent!', TOAST_TYPE.success)
            return dispatch({
                type: ADMIN_ACTION_TYPES.SEND_ACCESS_CODES,
                payload: response.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.SEND_ACCESS_CODES_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onGetAccessCodes = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                ADMIN_ENDPOINTS.ACCESS_CODES_LEFT + id
            )
            if (response.status !== 200 || !response.data) {
                showToast(
                    response?.data?.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.ACCESS_CODES_LEFT_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.ACCESS_CODES_LEFT,
                payload: response.data?.data,
            })
            // return {
            //     data: response?.data?.results,
            //     page: params && params.page ? params.page - 1 : 0,
            //     totalCount: response?.data?.count,
            // }
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.ACCESS_CODES_LEFT_FAILED,
            })
        }
    }
}
//Admin Get Events
export const onGetSecurityHolders = (id, params, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                ADMIN_ENDPOINTS.SECURITY_HOLDERS + id,
                {params}
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    response?.data?.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.SECURITY_HOLDERS_FAILED,
                })
            }

            console.log('response.data', response.data)

            dispatch(setAppLoadingState(false))
            dispatch({
                type: ADMIN_ACTION_TYPES.SECURITY_HOLDERS,
                payload: response.data,
            })
            return {
                data: response?.data?.results,
                page: params && params.page ? params.page - 1 : 0,
                totalCount: response?.data?.count,
            }
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.SECURITY_HOLDERS_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onGetSecurityHoldersSubmissionReview = (id, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                ADMIN_ENDPOINTS.SECURITY_HOLDERS_SUBMISSION_REVIEW + id
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    response?.data?.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW,
                payload: response.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onGetClientDropdownList = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                ADMIN_ENDPOINTS.CLIENT_DROPDOWN_LIST
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    response?.data?.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.CLIENT_DROPDOWN_LIST_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.CLIENT_DROPDOWN_LIST,
                payload: response.data?.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.CLIENT_DROPDOWN_LIST_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onSubmissionReviewAction = (body, accepted, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                ADMIN_ENDPOINTS.SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION,
                body
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    response?.data?.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(true))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            if (accepted) {
                showToast('Submission has been accepted!', TOAST_TYPE.success)
            } else {
                showToast('Submission has been rejected!', TOAST_TYPE.success)
            }
            navigate(-1)
            return dispatch({
                type: ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION,
                payload: response.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION_FAILED,
            })
        }
    }
}

//Admin Get Events
export const onRunActivityReport = (id, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                ADMIN_ENDPOINTS.RUN_ACTIVITY_REPORT + id
            )
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    response?.data?.message || 'Something Went Wrong!',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return dispatch({
                    type: ADMIN_ACTION_TYPES.RUN_ACTIVITY_REPORT_FAILED,
                })
            }

            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.RUN_ACTIVITY_REPORT,
                payload: response.data?.data,
            })
        } catch (err) {
            dispatch(setAppLoadingState(false))
            return dispatch({
                type: ADMIN_ACTION_TYPES.RUN_ACTIVITY_REPORT_FAILED,
            })
        }
    }
}

export const set2FA = (input, navigate, redirection) => {
    return async (dispatch) => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(ADMIN_ENDPOINTS.SETUP_2FA, input)
            if (response.status !== 200 || !response.data) {
                if (response.status === 401) {
                    adminLogout(navigate)
                }
                showToast(
                    'Error setting 2 factor authentication',
                    TOAST_TYPE.error
                )
                dispatch(setAppLoadingState(false))
                return false
            }
            showToast('2 Factor authentication successfully', TOAST_TYPE.sucess)
            dispatch(setAppLoadingState(false))
            if (redirection) {
                navigate('/admin-clients')
            } else {
                dispatch({
                    type: ADMIN_AUTH_ACTION_TYPES.SET_2FA,
                    payload: response?.data?.data,
                })
            }
            return true
        } catch (err) {
            showToast('Error setting 2 factor authentication', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
            return false
        }
    }
}
