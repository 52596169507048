import { FC } from 'react'
import { imgs } from '../../assets/images/img'
import { useSelector } from 'react-redux'
import { TimelineComponent } from '../timeline'
import { RootState } from '../../redux/rootstore'

type NavProps = {
    enableProps: boolean
    adminLogin?: boolean
}

export const Nav: FC<NavProps> = (props) => {
    const {event:eventData, user} = useSelector((state: RootState) => state && state.VerificationReducer && state.VerificationReducer)
    
    return (
        <div className="w-full flex flex-col lg:flex-row overflow-hidden items-center justify-between text-blue-base bg-white py-1 px-5 sm:px-8 border-b-2 border-blue-base">
            <div className='flex lg:w-1/3 justify-between'>
                <div className="flex flex-col items-center lg:mr-4">
                    <img
                        src={imgs.logo}
                        alt=""
                        className='w-56'
                    />
                    <div className="self-end Roboto-Medium text-xs sm:text-sm ">
                        XPRESS
                    </div>
                </div>
                <span className='flex items-center hidden lg:block'>{user?.securityholder_detail?.holder_reg1 || ''} {user?.securityholder_detail?.holder_reg2 || ''} {user?.securityholder_detail?.holder_reg3 || ''}</span>
            </div>
            {props?.adminLogin ? '':
            !props?.enableProps && <div className="Roboto-Bold text-sm sm:text-base md:text-lg hidden lg:block">
                {/* {eventData.eventtype} */}
            </div>}
            {props?.enableProps && <TimelineComponent />}
            <div className="flex items-center justify-between space-x-0 md:space-x-8 hidden lg:block">
            {props?.adminLogin ? '':eventData && eventData.logo ? <img src={eventData?.logo} className="h-12 m-auto" alt="logo" />:''}
                </div>
        </div>
    )
}
