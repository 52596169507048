import { useEffect, createRef, useState, useRef } from 'react'
import { FaSearch, FaTimes, FaUpload } from 'react-icons/fa'
import { ReactMaterialTable } from '../../components/table/customtable'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Input } from '../../components/input/input'
import { imgs } from '../../assets/images/img'
import { ModalComponent } from '../../components/Modal'
import { onCreateClient, onGetClient } from '../../redux/actions/admin.actions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { handleUpload, showToast } from '../../utils/functions'
import { TOAST_TYPE } from '../../utils/constants'

export const AdminClientScreen = () => {
    const dispatch = useDispatch<any>()
    const tableRef = createRef();
    const hiddenFileInput = useRef<any>(null);
    const navigate = useNavigate();
    const { clients, client_added } = useSelector((state: any) => state.AdminReducer, shallowEqual)
    const [queryParams, setQueryParams] = useState<
        {
            search: string
            status: string
        }[]
    >([])


    const handleClick = event => {
        if (event === 'hiddenFileInput') {
            hiddenFileInput.current.click();
        }
    };

    const handleChangeLocal = async (file: any, key: string) => {
        if (file) {
            const res = await handleUpload(file.target.files[0])
            console.log('resres', res);
            if (res.status) {
                formik.setFieldValue(key, res.data.location)
                formik.setFieldValue(key + '_name', res.data.key)
                const message = 'logo uploaded!'
                showToast(message, TOAST_TYPE.success)
            } else {
                showToast('Error uploading logo, Try Again!', TOAST_TYPE.error)
            }
        }
    }


    console.log('clients', clients);


    useEffect(() => {
        if (client_added) {
            formik.setValues({
                companyName: '',
                logo: '',
                email: '',
                personName: '',
                telephone: '',
                address: '',
            });
            setmodal(false)
            setLoading(false)
            dispatch(onGetClient('', navigate))
        }
    }, [client_added])

    const [searchedInputStatus, setsearchedInputStatus] =
        useState<boolean>(false)
    const [searchedList, setsearchedList] = useState<
        {
            client_company_name: string
            contact_person_name: string
            address: string
            telephone: string
            email: string
        }[]
    >([])



    const onEnterInput = (e: any, searchedBy: string, input: boolean) => {
        let query = { ...queryParams }
        if (e.target.value) {
            query[searchedBy] = e.target.value
        } else {
            delete query[searchedBy]
        }
        setQueryParams(query)
        tableRef.current && (tableRef.current as any).onQueryChange({ page: 0 })
    }

    const formik = useFormik({
        initialValues: {
            companyName: '',
            email: '',
            logo: '',
            personName: '',
            telephone: '',
            address: '',
        },
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setLoading(true)
            const { email, address, companyName, personName, telephone, logo } = values
            const payload = {
                "client_company_name": companyName,
                "contact_person_name": personName,
                "address": address,
                "telephone": telephone,
                "email": email
            }
            logo && (payload['logo'] = logo)
            const res = await dispatch(onCreateClient(payload, navigate))
            setLoading(false)
            console.log('res', res);

        },
    })

    const [modal, setmodal] = useState(false)
    const [loading, setLoading] = useState(false)
    const modalContent = (
        <div className="bg-white   outline-none  space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2.5/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => setmodal(!modal)}
                    className="cursor-pointer"
                />
            </div>
            <div className="space-y-8 px-10 pt-5 pb-10">
                <div className="flex flex-col items-center">
                    <img
                        src={imgs.logo}
                        alt=""
                        className="h-6 w-full sm:h-16 object-contain"
                    />
                    <div className="self-end Roboto-Medium text-blue-base text-xs sm:text-sm ">
                        XPRESS
                    </div>
                </div>
                <div className="Roboto-Medium text-blue-base text-center text-xl lg:text-2xl">
                    Are you sure you want to send login credentials to:
                </div>
                <div className="text-blue-base Roboto-Bold">
                    {formik.values.email}
                </div>
                <div className="space-y-3">
                    <button
                        className="btn-brown w-full "
                        onClick={() => {
                            formik.handleSubmit()
                        }}
                    >
                        SEND
                    </button>
                </div>
            </div>
        </div>
    )

    var columns = [
        {
            field: 'event_type',
            title: 'ISSUER NAME',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.client_company_name}</div>
                </div>
            ),
        },
        {
            field: 'event_date',
            title: 'CONTACT',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.contact_person_name}</div>
                </div>
            ),
        },
        {
            field: 'total_securities_exchanged',
            title: 'ADDRESS',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.address}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_securities_unexchanged',
            title: 'EMAIL',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.email}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_securities_unexchanged',
            title: 'TELEPHONE',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.telephone}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_securities_unexchanged',
            title: 'NUMBER OF SECURITYHOLDERS',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.number_of_securityholders}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_securities_unexchanged',
            title: 'NUMBER OF EVENTS',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.number_of_events}
                    </div>
                </div>
            ),
        },
    ]
    return (
        <div className="page-padding py-10">
            <div className="flex items-center w-full space-y-4 md:space-y-0 flex-col md:flex-row md:w-8/12 lg:w-1/2 md:space-x-10">
                <div className="h-7 w-full sm:h-8 md:h-10 relative flex items-center space-x-3 px-2 md:px-4 bg-white text-gray border  border-bordergray">
                    <input
                        className={`h-full text-gray w-full outline-none px-2 text-xs sm:text-sm 2xl:text-base pace-x-2 sm:space-x-4 2xl:space-x-8 `}
                        onChange={(e: any) =>
                            onEnterInput(e, 'search', true)
                        }
                        placeholder="Search"
                    />
                    <FaSearch className="text-sm md:text-lg lg:text-xl 2xl:text-2xl bg-white text-home-layout-gray" />

                    {searchedInputStatus && searchedList.length > 0 && (
                        <div className="w-full bg-white z-50 p-2 absolute -left-3 text-xs top-7 border border-meeting-cardBorder rounded-sm">
                            {searchedList.map((item, index) => (
                                <div key={index}>{item.client_company_name}</div>
                            ))}
                        </div>
                    )}
                </div>
                {/* <div
                    className={`md:w-1/3 h-7 sm:h-8 flex items-center px-2 text-blue-normal md:h-10 outline-none border border-blue-base Roboto-Medium text-sm`}>
                    <select
                        onChange={(e: any) =>
                            onEnterInput(e, 'event_status', false)
                        }
                        className={`h-full outline-none w-full`}>
                        <option value="EVENT_STATUS">ISSUER</option>
                        {tableData.map((item: any, index: any) => (
                            <option key={index} value={item.event_status}>
                                {item.event_status}
                            </option>
                        ))}
                    </select>
                </div> */}
            </div>
            {
                loading ?
                    <CircularProgress style={{ color: 'rgb(180 135 63)', width: 20, height: 20 }} />
                    :
                    <div className="py-10">
                        <ReactMaterialTable
                            tableRef={tableRef}
                            columns={columns}
                            data={(query) => (
                                new Promise(async (resolve, reject) => {
                                    const page = query.page ? query.page + 1 : 1
                                    const params = { ...queryParams, page }
                                    const result = await dispatch(onGetClient(params, navigate))
                                    return resolve(result)
                                }))
                            }
                            // data={searchedList.length > 0 ? searchedList : clients?.results}
                            searchbar={false}
                            toolbar={false}
                            exportbtn={false}
                            fontSize="16px"
                            pagging={true}
                            id="table"
                        />
                    </div>
            }

            <div
                className="border border-gray2 p-10 space-y-4">
                <div className="text-xl lg:text-2xl text-blue-base Roboto-Bold">
                    + Create New Client
                </div>
                <div className="grid md:grid-cols-2 gap-8">
                    <Input
                        placeholder="Client Company Name"
                        name="companyName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyName}
                        width="full"
                    />
                    <Input
                        placeholder="Enter your Email Address"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        width="full"
                    />
                    <Input
                        placeholder="Contact Person Name"
                        name="personName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.personName}
                        width="full"
                    />
                    <Input
                        placeholder="Telephone"
                        name="telephone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.telephone}
                        width="full"
                    />
                    <Input
                        placeholder="Address"
                        name="address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                        width="full"
                    />
                    <div>
                        <button className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                            <FaUpload />
                            <input ref={hiddenFileInput} style={{ display: 'none' }} type="file" accept='image/png, image/gif, image/jpeg' onChange={(e) => handleChangeLocal(e, 'logo')} />
                            <span onClick={() => handleClick('hiddenFileInput')}>UPLOAD LOGO</span>
                        </button>
                        {
                            formik.values['logo_name'] &&
                            <div className='text-xs' style={{ marginBottom: -20 }}>{formik.values['logo_name']}</div>
                        }
                    </div>
                </div>
                    <div className="h-7 sm:h-8 md:h-10 flex items-center justify-end">
                        <button
                            onClick={() => setmodal(!modal)}
                            className="bg-brown text-white px-5 h-full">
                            CREATE CLIENT
                        </button>
                    </div>
            </div>
            <ModalComponent
                modalState={modal}
                handleModal={() => setmodal(!modal)}
                content={modalContent}
            />
        </div >
    )
}
