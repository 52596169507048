import {FC} from 'react'
type TableHeadProps = {
    headings: any
}
export const TableHead: FC<TableHeadProps> = ({headings}) => {
    return (
        <thead className="bg-blue-normal text-white">
            <tr className={``}>
                {headings?.map((item: any, index: number) => (
                    <th
                        key={index}
                        className="text-center p-5 text-sm  border border-black-normal ">
                        {item}
                    </th>
                ))}
            </tr>
        </thead>
    )
}
