import {useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {imgs} from '../../assets/images/img'
import {Nav} from '../../components/nav/nav'
import {Footer} from '../../components/footer/footer'
import {InputPassword} from '../../components/inputPassword/inputPassword'
import {useDispatch, useSelector} from 'react-redux'
import {onHome, onLogin} from '../../redux/actions/auth.actions'
import { useEffect, useState } from 'react'
import { ModalComponent } from '../../components/Modal'
import LoginError from '../admin/LoginError'

export const Event = () => {
    
    const [loginErrorModal, setLoginErrorModal] = useState(false)
    const [loginErrorMessage, setLoginErrorMessage] = useState("")
    const navigate = useNavigate()
    const {eventId} = useParams();
    const dispatch = useDispatch()
    const eventData = useSelector(selector=> selector && selector.VerificationReducer && selector.VerificationReducer.event)|| {}

    useEffect(() => {
        dispatch(onHome(Number(eventId), navigate))    
    }, [])
    

    const formik = useFormik({
        initialValues: {
            accountNumber: '',
            accountPin: '',
        },
        validationSchema: Yup.object({
            accountNumber: Yup.string()
            .min(3, 'Atleast 3 digits.')//.min(10, 'Must be 10 digits.')
                .max(10, 'Atmost 10 digits')
                .required('Account Number Required'),
            accountPin: Yup.string()
                .min(12, 'Must be 12 digits.')
                .max(12, 'Must be 12 digits.')
                .required('Account Pin Required'),
        }),
        onSubmit: async (values) => {
            const {accountNumber, accountPin} = values
            let res = await dispatch(onLogin({accountNumber, accountPin, eventId:eventData.event_id}, navigate))
            if(res.isEnded === "True") {
                setLoginErrorMessage(res.message)
                setLoginErrorModal(true)

            } 
            
        },
    })
    return (
        <div
            className="bg-cover w-full h-full flex flex-col items-center justify-between min-h-screen"
            style={{
                backgroundImage: `url(${imgs.background})`,
            }}>
            <div
                className="bg-cover w-full h-full flex flex-col items-center justify-between min-h-screen"
                style={{
                    backgroundImage: `url(${imgs.backgroundlayer})`,
                }}>
                <Nav enableProps={false} />
                <div className="flex justify-center w-full py-10 text-left min-h-full md:space-x-10 xl:space-x-16">
                    <div className="text-white w-1/2 lg:w-5/12 space-y-6 px-12 hidden sm:block">
                        <div className="text-2xl md:text-4xl Roboto-Bold">
                            {eventData.event_name}
                        </div>
                        <div className="text-base md:text-lg md:h-60 Roboto-Regular c-word-break">
                            {eventData.description && eventData.description.substring(0,500)}
                        </div>
                    </div>
                    <div className="bg-white px-8 py-14 max-h-96  h-72 sm:h-80 flex items-center">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="space-y-8">
                            <div className="space-y-3">
                                <InputPassword
                                    placeholder="Enter your account number"
                                    name="accountNumber"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.accountNumber}
                                    errors={formik?.errors?.accountNumber}
                                    touched={formik?.touched?.accountNumber}
                                />
                                <InputPassword
                                    placeholder="Enter your PIN"
                                    name="accountPin"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.accountPin}
                                    errors={formik?.errors?.accountPin}
                                    touched={formik?.touched?.accountPin}
                                />
                            </div>
                            {formik.values.accountNumber && formik.values.accountPin &&
                            <button className="btn-brown w-full " type="submit">
                                Continue
                            </button>
}
                        </form>
                    </div>
                </div>
                <ModalComponent
                modalState={loginErrorModal}
                handleModal={() => setLoginErrorModal(!loginErrorModal)}
                content={<LoginError modal={loginErrorModal} setmodal={setLoginErrorModal} message={loginErrorMessage} />}
            />
                <Footer />
            </div>
        </div>
    )
}
