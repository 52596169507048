import { useEffect, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactMaterialTable } from '../../components/table/customtable'
import { onGetEventDetails, onRunActivityReport } from '../../redux/actions/admin.actions'
import moment from 'moment'
import { getActivityReport } from '../../redux/actions/client.actions'

export const AdminEventDetail = () => {
    const navigate = useNavigate()
    const { id } = useParams<any>()
    const dispatch = useDispatch<any>()
    const { eventDetails, eventHolder } = useSelector((state: any) => state.AdminReducer, shallowEqual)

    useEffect(() => {
        if (id) {
            const query = `?event_id=${id}`
            const query1 = id
            dispatch(onGetEventDetails(query, query1, '', navigate))
        }
    }, [id])

    const _onRunActivityReport = async () => {
        dispatch(getActivityReport(id, navigate))
    }

    var columns = [
        {
            field: 'holder_name',
            title: 'HOLDER NAME',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium text-blue-base Roboto-Medium">
                        {rowData?.holder_name}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_securities_held',
            title: 'TOTAL SECURITIES HELD',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.total_securities_held}</div>
                </div>
            ),
        },
        {
            field: 'total_securities_exchanged',
            title: 'TOTAL SECURITIES EXCHANGED',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.total_securities_exchanged}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_securities_issued',
            title: 'TOTAL SECURITIES ISSUED',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="text-brown Roboto-Bold">{rowData?.total_securities_issued}</div>
                </div>
            ),
        },
        {
            field: 'total_funds_paid',
            title: 'TOTAL FUNDS PAID',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.total_funds_paid}
                    </div>
                </div>
            ),
        },
    ]

    const query2 = `?event_id=${id}`
    return (
        <div className="page-padding py-10">
            <div className="border space-y-12  border-gray2 p-8">
                <div className="grid md:grid-cols-2 md:gap-20  w-full space-y-4  text-sm 2xl:text-base Roboto-Regular">
                    <div className="flex flex-col space-y-10 justify-between">
                        <div className="space-y-10">
                            <div className="Roboto-Medium text-base md:text-2xl text-blue-base">
                                EVENT ID: {eventDetails?.id}
                            </div>
                            <div className="Roboto-Medium text-base md:text-2xl text-blue-base">
                                EVENT NAME: {eventDetails?.event_name}
                            </div>
                        </div>
                        <div className="space-y-3">
                            <div className="h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                                Client Name: {eventDetails?.client_company_name}
                            </div>
                            <div className="h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                                Event Type: {eventDetails?.event_type}
                            </div>
                        </div>
                    </div>
                    <div className=" space-y-2 flex flex-col">
                        <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-gray2">
                            Event detail Information:
                        </div>
                        <div className='c-word-break'>
                            {eventDetails?.description}
                        </div>
                        <div className=" py-2 Roboto-Regular text-sm text-black-normal border-b border-gray2">
                            <p className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-t mt-4 pt-2 border-gray2">
                                Number of securityholders: {eventDetails?.number_of_securityholders}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-end">
                    <button className="btn-brown uppercase px-8 mr-4" onClick={() => navigate(`/admin-events/edit/${id}`)}>
                        {'See details'}
                    </button>
                    <button className="btn-brown uppercase px-8 " onClick={_onRunActivityReport}>
                        {'Run activity report'}
                    </button>
                </div>
            </div>
            <div className="py-10">
                <ReactMaterialTable
                    columns={columns}
                    data={(query) => (
                        new Promise(async (resolve, reject) => {
                            const page = query.page ? query.page + 1 : 1
                            const params = { page }
                            const result = await dispatch(onGetEventDetails(query2, id, params))
                            return resolve(result)
                        }))
                    }
                    // data={eventHolder?.results||[]}
                    searchbar={false}
                    toolbar={false}
                    exportbtn={false}
                    fontSize="16px"
                    pagging={true}
                    id="table"
                />
            </div>
        </div>
    )
}
