import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../../components/button/button'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {Header} from '../../../../components/header/header'
import {Input} from '../../../../components/input/input'
import {arrangementEvent, consolidationReverse} from '../../../constants'
import {InputWithLabel} from '../../../../components/InputWithLabel/InputWithLabel'
import {Checkbox} from '../../../../components/checkbox/checkbox'
import {FaUpload} from 'react-icons/fa'

export const ArrangementQuestions = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            cash: '',
        },
        validationSchema: Yup.object({
            cash: Yup.string()
                .matches(/^[0-9,.]*$/)
                .required('Cash Required'),
        }),
        onSubmit: (values) => {
            console.log(values)
            alert(JSON.stringify(values, null, 2))
            navigate('/security-holder/arrangement-event-entitlement')
        },
    })

    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav enableProps={true} />
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="w-11/12 md:w-9/12 mx-auto py-12 flex flex-col items-center justify-center space-y-10">
                <div className="Roboto-Bold w-full text-base md:text-xl text-blue-base text-left">
                    ADDITIONAL QUESTIONS
                </div>
                <hr className="border border-gray2 w-full" />

                <div className="w-1/2 self-start">
                    <InputWithLabel
                        label="Election for cash or securities or both"
                        placeholder="Please enter your answer"
                        name="cash"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cash}
                        errors={formik?.errors?.cash}
                        touched={formik?.touched?.cash}
                        width="full"
                    />
                </div>

                <div className="sm:w-10/12   self-start space-y-6">
                    <div className="space-y-1 w-full">
                        <div className="flex sm:items-center justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                            <div className="Roboto-Bold text-sm sm:text-base lg:text-xl text-blue-base md:text-center">
                                RESIDENCY DECLARATION
                            </div>
                            <div className="grid grid-cols-2 gap-2 sm:w-1/2">
                                <Checkbox
                                    text="US Securityholder"
                                    name="RESIDENCYDECLARATION"
                                    id="RESIDENCYDECLARATION1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'US Securityholder'}
                                />
                                <Checkbox
                                    text="Is not a US Securityholder"
                                    name="RESIDENCYDECLARATION"
                                    id="RESIDENCYDECLARATION2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'Is not a US Securityholder'}
                                />
                            </div>
                        </div>
                        {/* {formik.errors.IssueEntitlement && (
                        <div className="text-reddark text-xs">
                            {formik.errors.IssueEntitlement}
                        </div>
                    )} */}
                    </div>
                    <hr className="border border-gray2 w-full" />
                    <div className="flex justify-between text-blue-base text-xs md:text-sm Roboto-Medium py-5 sm:w-10/12">
                        <div className="Roboto-Bold text-sm sm:text-base lg:text-xl uppercase md:text-xl text-blue-base md:text-center">
                            Certification of accredited investor status
                        </div>
                        <button className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                            <FaUpload />
                            <span>Upload</span>
                        </button>
                    </div>
                    <div className="space-y-1 w-full">
                        <div className="flex space-y-2 justify-between md:space-y-0 flex-col md:flex-row md:space-x-10">
                            <div className="Roboto-Bold text-sm sm:text-base lg:text-xl text-blue-base md:text-center">
                                ARE YOU
                            </div>
                            <div className="grid grid-cols-2 gap-2  sm:w-1/2">
                                <Checkbox
                                    text="Individual"
                                    name="AREYOU"
                                    id="AREYOU1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'Individual'}
                                />
                                <Checkbox
                                    text="Entity"
                                    name="AREYOU"
                                    id="AREYOU2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'Entity'}
                                />
                            </div>
                        </div>
                        {/* {formik.errors.IssueEntitlement && (
                        <div className="text-reddark text-xs">
                            {formik.errors.IssueEntitlement}
                        </div>
                    )} */}
                    </div>
                    <div className="space-y-1 w-full">
                        <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row justify-between">
                            <div className="Roboto-Bold text-sm sm:text-base lg:text-xl text-blue-base md:text-center">
                                MARTIAL STATUS
                            </div>
                            <div className="grid grid-cols-2 gap-2  sm:w-1/2">
                                <Checkbox
                                    text="Single"
                                    name="MartialStatus"
                                    id="MartialStatus1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'Single'}
                                />
                                <Checkbox
                                    text="Married"
                                    name="MartialStatus"
                                    id="MartialStatus2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'Married'}
                                />
                            </div>
                        </div>
                        {/* {formik.errors.IssueEntitlement && (
                        <div className="text-reddark text-xs">
                            {formik.errors.IssueEntitlement}
                        </div>
                    )} */}
                    </div>
                    <div className="flex items-center justify-end space-x-4 md:space-x-8 text-blue-base text-xs md:text-sm Roboto-Medium py-5 w-full sm:w-10/12">
                        <div className="Roboto-Regular text-sm  text-blue-base md:text-center">
                            Upload Consent of Spouse
                        </div>
                        <button className=" border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                            <FaUpload />
                            <span>Upload</span>
                        </button>
                    </div>
                    <div className="flex items-center justify-end space-x-4 md:space-x-8 text-blue-base text-xs md:text-sm Roboto-Medium py-5 w-full sm:w-10/12">
                        <div className="Roboto-Medium text-sm  text-blue-base md:text-center">
                            Instructions to upload document that confirms
                            signing authority
                        </div>
                        <button className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                            <FaUpload />
                            <span>Upload</span>
                        </button>
                    </div>
                </div>
                <div className="flex items-center justify-end w-full">
                    <button className="btn-brown w-1/2 md:w-1/4 " type="submit">
                        SUBMIT AND CONTINUE
                    </button>
                </div>
            </form>

            <Footer />
        </div>
    )
}
