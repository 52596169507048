export default {
    "logo": "",
    "ISSUER_NAME": "",
    "CORPORATE_ACTION_EVENT_NAME": "",
    "ACCOUNT_NUMBER": "",
    "REGISTRATION_1": "",
    "REGISTRATION_2": "",
    "REGISTRATION_3": "",
    "CERTS": [],
    "REGISTERED_SECURITYHOLDER": "",
    "REGISTERED_ADDRESS": "",
    "REGISTERED_CITY": "",
    "REGISTERED_PROV": "",
    "REGISTERED_POSTAL": "",
    "REGISTERED_COUNTRY": "",
    "ECONSENT_EMAIL": "",
    "DELIVERY_ADDRESS": "",
    "DELIVERY_CITY": "",
    "DELIVERY_PROV": "",
    "DELIVERY_POSTAL": "",
    "DELIVERY_COUNTRY": "",
    "CORPORATE_SIGNING_AUTHORITY_ENTITIES": ""
  }