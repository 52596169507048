import { FC } from 'react'

type TextAreaProps = {
    placeholder: string
    name: string
    onChange: any
    onBlur: any
    value: any
    errors?: any
    touched?: any
    width: string
    id?: string
    maxlength?: number
    disabled?: any
}

export const TextArea: FC<TextAreaProps> = (props) => (
    <div
        className={`space-y-1 flex flex-col items-center justify-center w-${props.width}`}>
        <textarea
            className={`h-15 sm:h-15 md:h-15 text-gray w-full outline-none px-2 text-xs sm:text-sm 2xl:text-base border space-x-2 sm:space-x-4 2xl:space-x-8 ${props.touched && props.errors
                ? 'border-reddark'
                : 'border-bordergray'} `}
            rows={6}
            maxlength={props.maxlength}
            {...props} />

        {props.touched && props?.errors && (
            <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                {props?.errors}
            </div>
        )}
    </div>
)
