import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {imgs} from '../../assets/images/img'

type LoginScreen = {
    heading?: string
    description?: string
}
export const Header: FC<LoginScreen> = ({heading, description }) => {
    const {event} = useSelector((state: any) => state.VerificationReducer, shallowEqual)
    return (
        <div
            style={{
                backgroundImage: `url(${imgs.background})`,
            }}
            className="bg-cover bg-no-repeat h-24 sm:h-52 w-full flex items-center justify-center text-white text-xl">
            <div
                style={{
                    backgroundImage: `url(${imgs.backgroundlayer})`,
                }}
                className="object-cover w-full flex flex-col items-center justify-center text-white text-xl lg:text-2xl space-y-4 h-24 sm:h-52">
                <div className="Roboto-Bold md:text-xl lg:text-2xl xl:text-3xl">
                    {heading ? heading : event.event_name}
                </div>
                <div className="Roboto-Medium text-sm md:text-base xl:text-lg">
                    {description ? description : event.eventtype}
                </div>
            </div>
        </div>
    )
}
