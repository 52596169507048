import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

export const Footer = ({event=false}) => {
    const navigate = useNavigate()
    return (
        <div className="bg-blue-base flex flex-col md:flex-row justify-between px-4 sm:px-5 py-4 w-full space-y-4 md:space-y-0">
            <div className="text-white flex">
                <div className="px-4 border-r text-sm Roboto-Medium border-white">
                    OdysseyXpress
                </div>
                <div className="px-4 text-xs Roboto-Regular md:w-80">
                    Odyssey's Corporate Event Gateway makes it simple, fast and easy for you to monitor and track the status of your corporate actions and other corporate events and for your registered holders to submit their instructions securely online.
                </div>
            </div>
            <div className="text-white  flex items-center justify-between md:justify-center space-x-4 text-xs">
                <div className='flex self-end'>
                    <div className='cursor-pointer border-r border-white px-2 underline whitespace-nowrap' onClick={() => navigate(event ? '/user/terms-and-conditions':'/terms-and-conditions')}>Term of Use</div>
                    <div className='cursor-pointer px-2 underline whitespace-nowrap' onClick={() => navigate(event ?'/user/privacy-policy' :'/privacy-policy')}>Privacy Policy</div>
                </div>
                <div className="flex flex-col space-y-2">
                    <span className="space-x-3 flex items-center">
                        {/* <FaEnvelope />
                        <span>corp.actions@odysseytrust.com</span> */}
                    </span>
                    <span className="space-x-3 flex items-center">
                        <FaPhoneAlt />
                        <span>Canada: 1 (587) 885-0960</span>
                    </span>
                    <span className="space-x-3 flex items-center">
                        <FaPhoneAlt />
                        <span>Toll-Free: 1 (888) 290-1175</span>
                    </span>
                </div>
            </div>
        </div>
    )
}
