import { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ModalComponent } from '../../components/Modal'
import { ReactMaterialTable } from '../../components/table/customtable'
import { imgs } from '../../assets/images/img'
import { onGetEvents, onGetSecurityHoldersSubmissionReview, onSubmissionReviewAction } from '../../redux/actions/admin.actions'
import { TextArea } from '../../components/textarea/input'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'

export const SecurityHoldersDetails = () => {
    const { id, sid } = useParams<any>()
    const dispatch = useDispatch<any>()
    const [modal, setmodal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingAccept, setLoadingAccept] = useState(false)
    const [reason, setreason] = useState('')
    const { security_holders_submission_review } = useSelector((state: any) => state.AdminReducer, shallowEqual)

    useEffect(() => {
        if (sid) {
            dispatch(onGetSecurityHoldersSubmissionReview(sid, navigate))
        }
    }, [])

    const navigate = useNavigate()

    const handleSubmit = async () => {
        console.log('handleSubmit');
        setLoading(true)
        const body = {
            submission_id: sid,
            submission_status: 'rejected',
            reason_for_rejection: reason
        }
        await dispatch(onSubmissionReviewAction(body, false, navigate))
        setLoading(false)

    }

    const handleSubmitAccept = async () => {
        setLoadingAccept(true)
        const body = {
            submission_id: sid,
            submission_status: 'completed',
        }
        await dispatch(onSubmissionReviewAction(body, true, navigate))
        setLoadingAccept(false)

    }

    console.log('security_holders_submission_review', security_holders_submission_review);

    const modalContent = (
        <div className="bg-white   outline-none  space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2.5/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => setmodal(!modal)}
                    className="cursor-pointer"
                />
            </div>
            <div className="space-y-8 px-10 pt-5 pb-10">
                <div className="flex flex-col items-center">
                    <img
                        src={imgs.logo}
                        alt=""
                        className="h-6 w-full sm:h-16 object-contain"
                    />
                    <div className="self-end Roboto-Medium text-blue-base text-xs sm:text-sm ">
                        XPRESS
                    </div>
                </div>
                <div className="Roboto-Medium text-blue-base text-center text-lg lg:text-lg">
                    Please provide the reason for rejection of submission:
                </div>
                <div className="text-blue-base Roboto-Bold">
                    <TextArea
                        placeholder="Reason for rejection"
                        name="description"
                        onChange={(value) => setreason(value.target.value)}
                        onBlur={() => console.log()}
                        value={reason}
                        width="full"
                    />
                </div>
                <div className="space-y-3">
                    <button
                        className="btn-brown w-full "
                        disabled={!reason}
                        onClick={() => {
                            handleSubmit()
                        }}
                    >
                        {
                            loading ?
                                <CircularProgress style={{ color: 'rgb(180 135 63)', width: 20, height: 20 }} />
                                :
                                'SEND'
                        }
                    </button>
                </div>
            </div>
        </div>
    )

    function numberWithCommas(x) {
        return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="page-padding py-10">
            <div className="border space-y-12  border-gray2 p-8">
                <div className="grid  md:gap-20  w-full space-y-4  text-sm 2xl:text-base Roboto-Regular">
                    <div className="grid md:grid-cols-2 item-center justify-between">
                        <div className="Roboto-Bold text-base md:text-lg text-black-normal">
                            {security_holders_submission_review?.data?.full_name}
                        </div>
                        <span className="Roboto-Bold text-base uppercase text-right md:text-sm text-black-normal">
                            SUBMISSION STATUS: {security_holders_submission_review?.data?.submission_status}
                        </span>
                    </div>
                    <div className="flex flex-col space-y-10 justify-between">
                        <div className="space-y-3">
                            <div className="h-10 Roboto-Bold text-base md:text-sm text-black-normal  border-b border-gray2">
                                Number of securities remaining for exchange: {numberWithCommas(security_holders_submission_review?.data?.number_of_securities_remaining_for_exchange)}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-sm text-black-normal  border-b border-gray2">
                                Number of securities exchanged: {numberWithCommas(security_holders_submission_review?.data?.number_of_securities_exchanged)}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-sm text-black-normal  border-b border-gray2">
                                Total securities entitlement: {numberWithCommas(security_holders_submission_review?.data?.total_securities_entitlement)}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-sm text-black-normal  border-b border-gray2">
                                Total securities issued to date: {numberWithCommas(security_holders_submission_review?.data?.total_securities_issued_to_date)}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-sm text-black-normal  border-b border-gray2">
                                Total cash entitlement ($): {numberWithCommas(security_holders_submission_review?.data?.total_cash_entitlement)}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-sm text-black-normal  border-b border-gray2">
                                Amount paid to date ($): {numberWithCommas(security_holders_submission_review?.data?.amount_paid_to_date)}
                            </div>
                            <div className='h-5' />
                            <div className="flex item-center">
                                <div className="Roboto-Bold text-base md:text-lg text-black-normal">
                                    Uploaded Documents:
                                </div>
                                <div className='ml-4 mt-1'>

                                    <div className="Roboto-Bold cursor-pointer text-base underline mb-3 md:text-sm text-black-normal" onClick={() => security_holders_submission_review?.data?.securityholder_document?.length > 0 && security_holders_submission_review?.data?.securityholder_document[0]?.signing_authority && window.open(security_holders_submission_review?.data?.securityholder_document?.length > 0 && security_holders_submission_review?.data?.securityholder_document[0]?.signing_authority)}>
                                        Authority Document
                                    </div>
                                    <div className="Roboto-Bold text-base underline mb-3 md:text-sm text-black-normal">
                                        Copy of the Letter of transmital
                                    </div>
                                    <div className="Roboto-Bold text-base underline mb-3 md:text-sm text-black-normal">
                                        Document Number 1
                                    </div>
                                    <div className="Roboto-Bold text-base underline md:text-sm text-black-normal">
                                        W8-BEN-E
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                security_holders_submission_review?.data?.submission_status === 'approval_required' &&
                <div className="w-full flex justify-end mt-10">
                    <button onClick={handleSubmitAccept} className="btn-brown uppercase px-16 ">
                        {loadingAccept ? <CircularProgress style={{ color: 'rgb(180 135 63)', width: 20, height: 20 }} /> : 'ACCEPT'}
                    </button>
                    <button onClick={() => setmodal(true)} className="btn-brown uppercase ml-10 px-16 ">
                        {'REJECT'}
                    </button>
                </div>
            }
            <ModalComponent
                modalState={modal}
                handleModal={() => setmodal(!modal)}
                content={modalContent}
            />
        </div>
    )
}
