export const AUTH_ENDPOINTS = {
    LOGIN: '/api/v1/securityholder/securityholder_login',
    TERM_CONDITIONS: '/modules/terms-and-conditions/',
    PORTAL_TERM_CONDITIONS: '/api/v1/securityholder/mark_read_tnc_of_portal',
    EMAIL: '/api/v1/securityholder/add_email_for_invites',
    STATUS_BAR: '/api/v1/securityholder/update_status_bar',
    HOME: '/api/v1/securityholder/securityholder_home',
    EVENT_TERMS_AND_CONDITIONS:
        '/api/v1/securityholder/get_event_terms_and_condtions',
    UPDATE_EVENT_TERMS_AND_CONDITIONS:
        '/api/v1/securityholder/get_event_terms_and_condtions',
    CHECK_SECURITIES_HOLD: 'api/v1/securityholder/checktotal_securities_hold',

    CONSOLIDATION_EXCHANGE_DETAIL:
        '/api/v1/securityholder/consolidation_exchange_detail',
    POST_CONSOLIDATION_EVENT: '/api/v1/securityholder/consolidation_event',
    GET_WARRANT_DETAIL: '/api/v1/securityholder/warrant_detail',
    POST_WARRANT_EVENT: '/api/v1/securityholder/warrant_exercise_event',
    GET_PLAN_OF_ARRANGEMENT_DETAIL:
        '/api/v1/securityholder/plan_of_arrangement_detail',
    POST_PLAN_OF_ARRANGEMENT_EVENT:
        '/api/v1/securityholder/plan_of_arrangement_event',

    SIGNUP: '/auth/register',
    UPDATE: '/user/update-companyinfo',
    UPDATE_PROFILE: '/user/update',
    LOGOUT: '/api/v1/securityholder/securityholder_logout', //'/auth/logout',
    SESSION: '/auth/session-user',
    CHANGE_PASSWORD: '/user/change-password',
}

export const ADMIN_ENDPOINTS = {
    LOGOUT: '/api/v1/admin-logout',
    LOGIN: '/api/v1/admin/login',
    SEND_OTP: '/api/v1/admin/send_otp',
    CONFIRM_OTP: '/api/v1/admin/confirm_otp',
    RESET_PASSWORD: '/api/v1/reset_password',
    ADD_CLIENT: '/api/v1/admin/add_client',
    ADD_EVENT: '/api/v1/admin/add_event',
    UPDATE_EVENT: '/api/v1/admin/update_event',
    DELETE_EVENT_ENTITLEMENT: '/api/v1/admin/delete_entitlement/',
    GET_CLIENT: '/api/v1/admin/client_list',
    GET_EVENT_TYPE: '/api/v1/admin/event_type',
    GET_EVENTS: '/api/v1/admin/event_list',
    GENERATE_AND_GET_ACCESS_CODES:
        '/api/v1/admin/generate_and_get_access_codes/',
    GET_EVENT_DETAILS: '/api/v1/admin/event/',
    GET_EVENT_DETAIL_HOLDER: '/api/v1/admin/event_holders_data/',
    SEND_ACCESS_CODES: '/api/v1/admin/send_access_codes',
    ACCESS_CODES_LEFT: '/api/v1/admin/access_codes_left/',
    SECURITY_HOLDERS: '/api/v1/admin/securityholders_submissions/',
    SECURITY_HOLDERS_SUBMISSION_REVIEW:
        '/api/v1/admin/securityholder_submission_review/',
    CLIENT_DROPDOWN_LIST: '/api/v1/admin/client_dropdown_list',
    SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION:
        '/api/v1/admin/submission_review_action',
    RUN_ACTIVITY_REPORT: '/api/v1/client/event_activity_report/',
    SETUP_2FA: '/api/v1/admin/setup_2fa',
    CONFIRM_2FA_OTP: '/api/v1/admin/2fa/confirm_otp',
}

export const CLIENT_ENDPOINTS = {
    LOGIN: '/api/v1/client/login',
    LOGOUT: '/api/v1/client/logout',
    SEND_OTP: '/api/v1/client/send_otp',
    CONFIRM_OTP: '/api/v1/client/confirm_otp',
    CONFIRM_2FA_OTP: '/api/v1/client/2fa/confirm_otp',
    RESET_PASSWORD: '/api/v1/client/reset_password',
    UPDATE_PASSWORD: '/api/v1/client/change_password',
    UPDATE_PASSWORD_SEND_OTP: '/api/v1/client/change_password/send_otp',
    UPDATE_PASSWORD_CONFIRM_OTP: '/api/v1/client/change_password/confirm_otp',
    SETUP_2FA: '/api/v1/client/set_up_2fa',
    DASHBOARD: '/api/v1/client/home',
    EVENT_DETAIL: '/api/v1/client/event_detail',
    EVENT_PARTICIPANTS: '/api/v1/client/participants/',
    SECURITY_HOLDERS: '/api/v1/client/securityholders',
    ACTIVITY_REPORT: '/api/v1/client/event_activity_report/',
}
