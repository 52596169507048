import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import ImageModule from 'docxtemplater-image-module-free';

function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}
var opts = {}
opts.centered = true; //Set to true to always center images
opts.fileType = "docx"; //Or pptx

opts.getImage = function (tagValue, tagName) {
    return new Promise(async function (resolve, reject) {
        PizZipUtils.getBinaryContent(
            tagValue,
            function (error, content) {
                if (error) {
                    console.log("error downloading", error)
                    //return reject(error);
                }
                return resolve(content);
            }
        );
    });
}
opts.getSize = function (img, tagValue, tagName) {
    // FOR FIXED SIZE IMAGE :
    //return [50, 50];
    return new Promise(function (
        resolve,
        reject
    ) {
        const image = new Image();
        image.src = tagValue;
        image.onload = function () {
            resolve([
                image.width,
                image.height,
            ]);
        };
        image.onerror = function (e) {
            console.log(
                "img, tagValue, tagName : ",
                img,
                tagValue,
                tagName
            );
            alert(
                "An error occured while loading " +
                    tagValue
            );
            reject(e);
        };
    });
}

const generateDocument = (existingDoc, params, fileName) => {
    loadFile(
        existingDoc,
        async function (error, content) {
            if (error) {
                throw error;
            }
            var zip = new PizZip(content);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
                // modules: [new ImageModule(opts)],
            });
            try {
                let docout;
                if(params.logo){
                    docout = await doc.renderAsync(params)
                }else {
                    docout = await doc.render(params)
                }
                var out = doc.getZip().generate({
                    type: 'blob',
                    mimeType:
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });
                saveAs(out, fileName);
            }
            catch(error) {
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                function replaceErrors(key, value) {
                    if (value instanceof Error) {
                        return Object.getOwnPropertyNames(value).reduce(function (
                            error,
                            key
                        ) {
                            error[key] = value[key];
                            return error;
                        },
                            {});
                    }
                    return value;
                }
                console.log(JSON.stringify({ error: error }, replaceErrors));

                if (error.properties && error.properties.errors instanceof Array) {
                    const errorMessages = error.properties.errors
                        .map(function (error) {
                            return error.properties.explanation;
                        })
                        .join('\n');
                    console.log('errorMessages', errorMessages);
                    // errorMessages is a humanly readable message looking like this :
                    // 'The tag beginning with "foobar" is unopened'
                }
                throw error;
            }
        }
    );
};


export default generateDocument
