import {ADMIN_ACTION_TYPES} from '../types'

const INITIAL_STATE = {
    failed: false,
    success: false,
    client_added: false,
    event_added: false,
    generated: false,
    access_sent: false,
    logout: false,
    clients: [],
    generatedCodes: [],
    codes_left: [],
    events: [],
    event_types: [],
    security_holders: [],
    security_holders_submission_review: null,
    client_dropdown_list: [],
    eventDetails: null,
    eventHolder: null,
}

export const AdminReducer = (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case ADMIN_ACTION_TYPES.ADD_CLIENT:
            return {
                ...state,
                success: true,
                client_added: true,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.ADD_CLIENT_FAILED:
            return {
                ...state,
                client_added: false,
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.GENERATE_AND_GET_ACCESS_CODES:
            return {
                ...state,
                success: true,
                generated: true,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.GENERATE_AND_GET_ACCESS_CODES_FAILED:
            return {
                ...state,
                generated: false,
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.SEND_ACCESS_CODES:
            return {
                ...state,
                success: true,
                access_sent: true,
                generated: false,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.SEND_ACCESS_CODES_FAILED:
            return {
                ...state,
                access_sent: false,
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.ACCESS_CODES_LEFT:
            return {
                ...state,
                success: true,
                codes_left: actions.payload,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.ACCESS_CODES_LEFT_FAILED:
            return {
                ...state,
                codes_left: [],
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.SECURITY_HOLDERS:
            return {
                ...state,
                success: true,
                security_holders: actions.payload,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.SECURITY_HOLDERS_FAILED:
            return {
                ...state,
                security_holders: [],
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW:
            return {
                ...state,
                success: true,
                security_holders_submission_review: actions.payload,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW_FAILED:
            return {
                ...state,
                security_holders_submission_review: null,
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.CLIENT_DROPDOWN_LIST:
            return {
                ...state,
                success: true,
                client_dropdown_list: actions.payload,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.CLIENT_DROPDOWN_LIST_FAILED:
            return {
                ...state,
                client_dropdown_list: null,
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION_FAILED:
            return {
                ...state,
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.GET_GENERATE_AND_GET_ACCESS_CODES:
            return {
                ...state,
                success: true,
                generatedCodes: actions.payload,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.GET_GENERATE_AND_GET_ACCESS_CODES_FAILED:
            return {
                ...state,
                generated: false,
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.ADD_EVENT:
            return {
                ...state,
                codes_left: [],
                generatedCodes: [],
                success: true,
                event_added: true,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.ADD_EVENT_FAILED:
            return {
                ...state,
                generatedCodes: [],
                event_added: false,
                success: false,
                codes_left: [],
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.UPDATE_EVENT:
            return {
                ...state,
                codes_left: [],
                generatedCodes: [],
                success: true,
                event_added: true,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.UPDATE_EVENT_FAILED:
            return {
                ...state,
                generatedCodes: [],
                event_added: false,
                success: false,
                codes_left: [],
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.DELETE_EVENT_ENTITLEMENT:
            return {
                ...state,
                codes_left: [],
                generatedCodes: [],
                success: true,
                event_added: true,
                failed: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.DELETE_EVENT_ENTITLEMENT_FAILED:
            return {
                ...state,
                generatedCodes: [],
                event_added: false,
                success: false,
                codes_left: [],
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.GET_CLIENT:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
                client_added: false,
                clients: actions.payload,
            }
        case ADMIN_ACTION_TYPES.GET_CLIENT_FAILED:
            return {
                ...state,
                success: false,
                failed: true,
                client_added: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.GET_EVENT_TYPE:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
                client_added: false,
                event_types: actions.payload,
            }
        case ADMIN_ACTION_TYPES.GET_EVENT_TYPE_FAILED:
            return {
                ...state,
                success: false,
                failed: true,
                client_added: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.GET_EVENTS:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
                events: actions.payload,
            }
        case ADMIN_ACTION_TYPES.GET_EVENTS_FAILED:
            return {
                ...state,
                success: false,
                failed: true,
                client_added: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.GET_EVENT_DETAILS:
            return {
                ...state,
                success: true,
                failed: false,
                access_sent: false,
                logout: false,
                eventDetails: actions.payload,
                eventHolder: actions.eventHolder,
            }
        case ADMIN_ACTION_TYPES.GET_EVENT_DETAILS_FAILED:
            return {
                ...state,
                access_sent: false,
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.RUN_ACTIVITY_REPORT:
            return {
                ...state,
                success: true,
                failed: false,
                access_sent: false,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.RUN_ACTIVITY_REPORT_FAILED:
            return {
                ...state,
                access_sent: false,
                success: false,
                failed: true,
                logout: false,
            }
        case ADMIN_ACTION_TYPES.RESET_DETAILS:
            return {
                ...state,
                access_sent: false,
                generated: false,
                codes_left: [],
                generatedCodes: [],
                eventDetails: null,
                security_holders: [],
                success: false,
                failed: true,
                logout: false,
            }
        default:
            return state
    }
}
