import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {Header} from '../../../../components/header/header'
import {FaArrowLeft, FaDownload, FaUpload} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { handleDownload, showToast } from '../../../../utils/functions'
import { RootState } from '../../../../redux/rootstore'
import w8Template from '../../../../assets/doc/W8TEMPLATEINDIVIDUAL.pdf'
import w8TemplateEntity from '../../../../assets/doc/W8TEMPLATEENTITES.pdf'
import { TOAST_TYPE } from '../../../../utils/constants'
import { handleUploadDoc, prepareConsolidationExchangeDetail } from '../../../../redux/actions/auth.actions'

export const TAX_CERT_STEP4 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const hiddenFileInput = useRef<any>(null);
    const { event, user, consolidation_exchange_detail, consolidation_exchange } = useSelector((state: RootState) => state && state.VerificationReducer)
    const formik = useFormik({
        initialValues: {
            uplodedFile : consolidation_exchange?.tax?.w8_form
        },
        validationSchema: Yup.object({
            uplodedFile: Yup.string().required("File must be uploaded")
        }),
        onSubmit: (values) => {
            navigate('/review-instruction')
        },
    })
    const handleChangeLocal = async (file: any) => {
        if (file) {
            if(file.target.files[0].type === 'application/pdf'){
                const res = await dispatch(handleUploadDoc(file.target.files[0]))
                if(res.status){
                    let prevTax = consolidation_exchange.tax
                    formik.setFieldValue("uplodedFile", res.data.location)
                    dispatch(prepareConsolidationExchangeDetail({tax: {...prevTax , w8_form: res.data.location}}))
                }else {
                    showToast('Error uploading document, Try Again!', TOAST_TYPE.error)
                }
            } else {
                showToast('Only Pdf extensions are allowed', TOAST_TYPE.error)
            }
        }
    }
    return (
        <div className="w-full flex flex-col justify-between   min-h-screen h-screen">
            <div>
                <Nav
                    enableProps={true}
                />
               <Header
                    heading={event.event_name}
                    description="Tax Election"
                />
                <div className="page-padding space-y-8 py-10">
                    <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                        <FaArrowLeft /> <span>BACK</span>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="md:px-14 mx-auto flex flex-col space-y-10 accent-reddark">
                        <div className="space-y-1">
                            <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row justify-between">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                Please download the applicable Form W-8, complete, sign and
upload using the button below.
                                </div>
                            </div>
                        </div>
                        <hr className="border border-gray2" />
                        <div className="flex flex-col space-y-3">
                            <div className="flex flex-col md:flex-row  gap-2 items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                <div>Form W-8BEN (Individuals)</div>
                                <button onClick={()=>{
                                    handleDownload(w8Template,'w8TemplateIndividual')
                                }} 
                                className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center" type="button" >
                                    <FaDownload />
                                    <span>Download Template</span>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col space-y-3">
                            <div className="flex flex-col md:flex-row  gap-2 items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                <div>Form W-8BEN-E (Entities)</div>
                                <button onClick={()=>{
                                    handleDownload(w8TemplateEntity,'w8TemplateEntity')
                                }} 
                                className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center" type="button" >
                                    <FaDownload />
                                    <span>Download Template</span>
                                </button>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => hiddenFileInput.current.click()} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center" type="button" >
                                <FaUpload />
                                <input ref={hiddenFileInput} style={{ display: 'none' }} type="file" accept="application/pdf" onChange={(e) => handleChangeLocal(e)} />
                                <span>Upload</span>
                            </button>
                            {formik.values.uplodedFile && <iframe src={formik.values.uplodedFile} width={300}  />}
                        </div>
                        <div className="mt-10  flex flex-col items-end space-y-6 justify-end w-full ">
                            {formik.values.uplodedFile && <button className="btn-brown w-44" type="submit">
                                CONTINUE
                            </button>}
                        </div>
                    </form>
                    
                </div>
            </div>

            <Footer />
        </div>
    )
}
