import { CLIENT_AUTH_ACTION_TYPES } from '../types'

const INITIAL_STATE = {
    failed: false,
    success: false,
    logout: false,
    client_detail:{},
    otp_result: {},
    client_events: {},
    event_detail: {},
    event: {},
    event_participants: {},
    security_holders: {},
    security_holder_detail: {}
}

export const ClientReducer = (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case CLIENT_AUTH_ACTION_TYPES.SET_CLIENT_DETAIL:
            return {
                ...state,
                client_detail: actions.payload
            }
        case CLIENT_AUTH_ACTION_TYPES.SET_2FA:
            return {
                ...state,
                client_detail: {...state.client_detail, is2fa: actions.payload}
            }
        case CLIENT_AUTH_ACTION_TYPES.CONFIRM_OTP:
            return {
                ...state,
                otp_result: actions.payload
            }
        case CLIENT_AUTH_ACTION_TYPES.CLIENT_DASHBOARD:
            return {
                ...state,
                client_events: actions.payload
            }
        case CLIENT_AUTH_ACTION_TYPES.SET_EVENT_DETAIL:
            return {
                ...state,
                event_detail: actions.payload
            }
        case CLIENT_AUTH_ACTION_TYPES.SET_EVENT_PARTICIPANTS:
            return {
                ...state,
                event_participants: actions.payload
            }
        case CLIENT_AUTH_ACTION_TYPES.SET_SECURITY_HOLDERS:
            return {
                ...state,
                security_holders: actions.payload
            }
        case CLIENT_AUTH_ACTION_TYPES.SET_SECURITY_HOLDER_DETAIL:
            return {
                ...state,
                security_holder_detail: actions.payload
            }
        default:
            return state
    }
}