import consolidationTemplateObject from './consolidationTemplateObject'
import warrantTemplateObject from './warrantTemplateObject'
import planofArrangementTemplateObject from './planofArrangementTemplateObject'
import ConsolidateTemplate from '../../../../assets/doc/ConsolidationSubmissionConfirmation.docx'
import PlanofArrangementTemplate from '../../../../assets/doc/PlanofArrangementSubmissionConfirmation.docx'
import WarrantExerciseTemplate from '../../../../assets/doc/WarrantExerciseConfirmation.docx'
import generateDocument from '../../../../utils/helpers/doc'
import {DOLLAR_STRING, NUMBER_FORMAT} from '../../../constants'

const RESIDENCY_HOLDER_MAP = {
    a: 'NOT A US PERSON OR IN THE UNITED STATES',
    b: 'IS A US PERSON OR IN THE UNITED STATES',
    c:
        'IS A US PERSON OR IS IN THE UNITED STATES, BUT WITH AN EXEMPTION FROM REGISTRATION REQUIREMENTS  (send this form with Accredited Investor Certificate or Written Opinion of US counsel as described more fully in the terms of the Warrants or the 1933 Act legend will be affixed to the shares issued)',
}
const PAYMENT_METHOD = {
    wire: 'WIRE',
    cheque: 'CHEQUE',
    direct_deposit: 'DIRECT DEPOSIT',
    ach: 'DIRECT DEPOSIT',
}

export const download = (
    event,
    user,
    consolidation_exchange,
    warrant_request,
    warrant_detail,
    consolidation_exchange_detail
) => {
    if (event.eventtype_id === 1) {
        let documetReplacerObject = consolidationReplacer(
            user,
            consolidation_exchange
        )
        generateDocument(
            ConsolidateTemplate,
            documetReplacerObject,
            'ConsolidationSubmissionConfirmation'
        )
    } else if (event.eventtype_id === 2) {
        let documetReplacerObject = warrantReplacer(
            user,
            warrant_request,
            warrant_detail
        )
        generateDocument(
            WarrantExerciseTemplate,
            documetReplacerObject,
            'WarrantExerciseConfirmation'
        )
    } else {
        let documetReplacerObject = planOfArrangementReplacer(
            user,
            consolidation_exchange,
            consolidation_exchange_detail
        )
        generateDocument(
            PlanofArrangementTemplate,
            documetReplacerObject,
            'PlanofArrangementSubmissionConfirmation'
        )
    }
}

const consolidationReplacer = (user, consolidation_exchange) => {
    // consolidationTemplateObject.logo = user?.event_detail?.logo || '';
    consolidationTemplateObject.ISSUER_NAME =
        user?.event_detail?.client_company_name || ''
    consolidationTemplateObject.CORPORATE_ACTION_EVENT_NAME =
        user?.event_detail?.event_name || ''
    consolidationTemplateObject.ACCOUNT_NUMBER =
        user?.securityholder_detail?.account_number || ''
    consolidationTemplateObject.REGISTRATION_1 =
        user?.securityholder_detail?.holder_reg1 || ''
    consolidationTemplateObject.REGISTRATION_2 =
        user?.securityholder_detail?.holder_reg2 || ''
    consolidationTemplateObject.REGISTRATION_3 =
        user?.securityholder_detail?.holder_reg3 || ''
    consolidationTemplateObject.CERTS = consolidation_exchange?.certs || []
    consolidationTemplateObject.REGISTERED_SECURITYHOLDER =
        consolidation_exchange?.issue_in_anothername?.full_name ||
        `${user?.securityholder_detail?.holder_reg1 || ''} ${
            user?.securityholder_detail?.holder_reg2 || ''
        } ${user?.securityholder_detail?.holder_reg3 || ''}` ||
        ''
    consolidationTemplateObject.REGISTERED_ADDRESS =
        consolidation_exchange?.issue_in_anothername?.street_address ||
        `${user?.securityholder_detail?.holder_address1 || ''} ${
            user?.securityholder_detail?.holder_address2 || ''
        } ${user?.securityholder_detail?.holder_address3 || ''}` ||
        ''
    consolidationTemplateObject.REGISTERED_CITY =
        consolidation_exchange?.issue_in_anothername?.city ||
        user?.securityholder_detail?.city ||
        ''
    consolidationTemplateObject.REGISTERED_PROV =
        consolidation_exchange?.issue_in_anothername?.state ||
        user?.securityholder_detail?.state ||
        ''
    consolidationTemplateObject.REGISTERED_POSTAL =
        consolidation_exchange?.issue_in_anothername?.zip_or_postal_code ||
        user?.securityholder_detail?.zipcode ||
        ''
    consolidationTemplateObject.REGISTERED_COUNTRY =
        consolidation_exchange?.issue_in_anothername?.country ||
        user?.securityholder_detail?.country ||
        ''
    consolidationTemplateObject.ECONSENT_EMAIL =
        user?.securityholder_detail?.email || ''
    consolidationTemplateObject.DELIVERY_ADDRESS =
        consolidation_exchange?.delivery_address?.street_address || ''
    consolidationTemplateObject.DELIVERY_CITY =
        consolidation_exchange?.delivery_address?.city || ''
    consolidationTemplateObject.DELIVERY_PROV =
        consolidation_exchange?.delivery_address?.state || ''
    consolidationTemplateObject.DELIVERY_POSTAL =
        consolidation_exchange?.delivery_address?.zip_or_postal_code || ''
    consolidationTemplateObject.DELIVERY_COUNTRY =
        consolidation_exchange?.delivery_address?.country || ''
    consolidationTemplateObject.CORPORATE_SIGNING_AUTHORITY_ENTITIES =
        consolidation_exchange?.signing_authority || ''
    return consolidationTemplateObject
}

const warrantReplacer = (user, warrant_request, warrant_detail) => {
    // warrantTemplateObject.logo = user?.event_detail?.logo || '';
    warrantTemplateObject.ISSUER_NAME =
        user?.event_detail?.client_company_name || ''
    warrantTemplateObject.CORPORATE_ACTION_EVENT_NAME =
        user?.event_detail?.event_name || ''
    warrantTemplateObject.ACCOUNT_NUMBER =
        user?.securityholder_detail?.account_number || ''
    warrantTemplateObject.REGISTRATION_1 =
        user?.securityholder_detail?.holder_reg1 || ''
    warrantTemplateObject.REGISTRATION_2 =
        user?.securityholder_detail?.holder_reg2 || ''
    warrantTemplateObject.REGISTRATION_3 =
        user?.securityholder_detail?.holder_reg3 || ''
    warrantTemplateObject.WARRANTS_SUBMITTED =
        NUMBER_FORMAT(warrant_request?.warrants_to_be_exercised) || ''
    warrantTemplateObject.WARRANTS_REMANING =
        NUMBER_FORMAT(
            Number(warrant_detail?.warrants_available_for_exercise || 0) -
                Number(warrant_request?.warrants_to_be_exercised || 0)
        ) || ''
    warrantTemplateObject.WARRANTS_ISSUED =
        NUMBER_FORMAT(warrant_request?.warrants_to_be_exercised) || ''
    warrantTemplateObject.REGISTERED_SECURITYHOLDER =
        warrant_request?.issue_in_anothername?.full_name ||
        `${user?.securityholder_detail?.holder_reg1 || ''} ${
            user?.securityholder_detail?.holder_reg2 || ''
        } ${user?.securityholder_detail?.holder_reg3 || ''}` ||
        ''
    warrantTemplateObject.REGISTERED_ADDRESS =
        warrant_request?.issue_in_anothername?.street_address ||
        `${user?.securityholder_detail?.holder_address1 || ''} ${
            user?.securityholder_detail?.holder_address2 || ''
        } ${user?.securityholder_detail?.holder_address3 || ''}` ||
        ''
    warrantTemplateObject.REGISTERED_CITY =
        warrant_request?.issue_in_anothername?.city ||
        user?.securityholder_detail?.city ||
        ''
    warrantTemplateObject.REGISTERED_PROV =
        warrant_request?.issue_in_anothername?.state ||
        user?.securityholder_detail?.state ||
        ''
    warrantTemplateObject.REGISTERED_POSTAL =
        warrant_request?.issue_in_anothername?.zip_or_postal_code ||
        user?.securityholder_detail?.zipcode ||
        ''
    warrantTemplateObject.REGISTERED_COUNTRY =
        warrant_request?.issue_in_anothername?.country ||
        user?.securityholder_detail?.country ||
        ''
    warrantTemplateObject.ECONSENT_EMAIL =
        user?.securityholder_detail?.email || ''
    warrantTemplateObject.DELIVERY_ADDRESS =
        warrant_request?.delivery_address?.street_address || ''
    warrantTemplateObject.DELIVERY_CITY =
        warrant_request?.delivery_address?.city || ''
    warrantTemplateObject.DELIVERY_PROV =
        warrant_request?.delivery_address?.state || ''
    warrantTemplateObject.DELIVERY_POSTAL =
        warrant_request?.delivery_address?.zip_or_postal_code || ''
    warrantTemplateObject.DELIVERY_COUNTRY =
        warrant_request?.delivery_address?.country || ''
    warrantTemplateObject.PAYMENT_DUE =
        DOLLAR_STRING.format(warrant_request?.total_amount_payable) || ''
    warrantTemplateObject.PAYMENT_METHOD = warrant_request?.payment_method || ''
    warrantTemplateObject.WIRE_UNIQUE_ID =
        warrant_request?.payment_method == 'wire'
            ? warrant_request?.wire_unique_id || ''
            : ''
    warrantTemplateObject.CORPORATE_SIGNING_AUTHORITY_ENTITIES =
        warrant_request?.signing_authority || ''
    warrantTemplateObject.RESIDENCY_HOLDER_STATUS = warrant_request.residency_holder_status
        ? RESIDENCY_HOLDER_MAP[warrant_request.residency_holder_status]
        : ''
    return warrantTemplateObject
}

const planOfArrangementReplacer = (
    user,
    consolidation_exchange,
    consolidation_exchange_detail
) => {
    // planofArrangementTemplateObject.logo = user?.event_detail?.logo || '';
    planofArrangementTemplateObject.ISSUER_NAME =
        user?.event_detail?.client_company_name || ''
    planofArrangementTemplateObject.CORPORATE_ACTION_EVENT_NAME =
        user?.event_detail?.event_name || ''
    planofArrangementTemplateObject.ACCOUNT_NUMBER =
        user?.securityholder_detail?.account_number || ''
    planofArrangementTemplateObject.REGISTRATION_1 =
        user?.securityholder_detail?.holder_reg1 || ''
    planofArrangementTemplateObject.REGISTRATION_2 =
        user?.securityholder_detail?.holder_reg2 || ''
    planofArrangementTemplateObject.REGISTRATION_3 =
        user?.securityholder_detail?.holder_reg3 || ''
    planofArrangementTemplateObject.CERTS = consolidation_exchange?.certs || []
    planofArrangementTemplateObject.REGISTERED_SECURITYHOLDER =
        consolidation_exchange?.issue_in_anothername?.full_name ||
        `${user?.securityholder_detail?.holder_reg1 || ''} ${
            user?.securityholder_detail?.holder_reg2 || ''
        } ${user?.securityholder_detail?.holder_reg3 || ''}` ||
        ''
    planofArrangementTemplateObject.REGISTERED_ADDRESS =
        consolidation_exchange?.issue_in_anothername?.street_address ||
        `${user?.securityholder_detail?.holder_address1 || ''} ${
            user?.securityholder_detail?.holder_address2 || ''
        } ${user?.securityholder_detail?.holder_address3 || ''}` ||
        ''
    planofArrangementTemplateObject.REGISTERED_CITY =
        consolidation_exchange?.issue_in_anothername?.city ||
        user?.securityholder_detail?.city ||
        ''
    planofArrangementTemplateObject.REGISTERED_PROV =
        consolidation_exchange?.issue_in_anothername?.state ||
        user?.securityholder_detail?.state ||
        ''
    planofArrangementTemplateObject.REGISTERED_POSTAL =
        consolidation_exchange?.issue_in_anothername?.zip_or_postal_code ||
        user?.securityholder_detail?.zipcode ||
        ''
    planofArrangementTemplateObject.REGISTERED_COUNTRY =
        consolidation_exchange?.issue_in_anothername?.country ||
        user?.securityholder_detail?.country ||
        ''
    planofArrangementTemplateObject.ECONSENT_EMAIL =
        user?.securityholder_detail?.email || ''
    planofArrangementTemplateObject.DELIVERY_ADDRESS =
        consolidation_exchange?.delivery_address?.street_address || ''
    planofArrangementTemplateObject.DELIVERY_CITY =
        consolidation_exchange?.delivery_address?.city || ''
    planofArrangementTemplateObject.DELIVERY_PROV =
        consolidation_exchange?.delivery_address?.state || ''
    planofArrangementTemplateObject.DELIVERY_POSTAL =
        consolidation_exchange?.delivery_address?.zip_or_postal_code || ''
    planofArrangementTemplateObject.DELIVERY_COUNTRY =
        consolidation_exchange?.delivery_address?.country || ''
    planofArrangementTemplateObject.ELECTION =
        consolidation_exchange?.election?.election_type ||
        consolidation_exchange_detail?.entitlement ||
        ''
    planofArrangementTemplateObject.CASH_METHOD =
        PAYMENT_METHOD[
            consolidation_exchange?.payment_instructions
                ?.withdrawal_payment_method
        ] || ''
    planofArrangementTemplateObject.TAX_STATUS = getTaxStatus(
        consolidation_exchange?.election?.residency_tax_declaration || ''
    )
    planofArrangementTemplateObject.RESIDENCY_STATUS =
        consolidation_exchange?.tax?.resident.toUpperCase() || ''

    planofArrangementTemplateObject.W9 = consolidation_exchange?.w9form
        ? 'W9'
        : ''
    planofArrangementTemplateObject.W8 = consolidation_exchange?.tax?.w8_form
        ? 'W8'
        : ''
    planofArrangementTemplateObject.CORPORATE_SIGNING_AUTHORITY = consolidation_exchange?.signing_authority
        ? 'CORPORATE SIGNING AUTHORITY'
        : ''
    planofArrangementTemplateObject.CONSENT_OF_SPOUSE = consolidation_exchange
        ?.election?.consent_of_spouse
        ? 'CONSENT OF SPOUSE'
        : ''
    return planofArrangementTemplateObject
}

var getTaxStatus = (input) =>
    input ? (input == 1 ? 'Not a US Holder' : 'US Holder') : ''
