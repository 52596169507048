import React from 'react';
import { FaTimes } from 'react-icons/fa'

let ValidationError = ({ modal, setmodal }) => {
    return (
        <div className="bg-white   outline-none  space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2.5/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => setmodal(!modal)}
                    className="cursor-pointer"
                />
            </div>
            <div className="space-y-8 px-10 pt-5 pb-10">

                <div className="text-blue-base text-center">
                    The information you entered is not correct based on our current records.
                    Please review your records and correct or contact us at <span className="Roboto-Bold">www.odysseycontact.com </span>
                    to confirm your holdings.
                    A Security Check must be passed.
                </div>

                <div className="space-y-3">
                    <button
                        className="btn-brown w-full "
                        onClick={() => {
                            setmodal(!modal)
                        }}
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ValidationError