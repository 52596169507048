import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../components/button/button'
import {Footer} from '../../../components/footer/footer'
import {Nav} from '../../../components/nav/nav'
import {Checkbox} from '../../../components/checkbox/checkbox'
import {InputWithLabel} from '../../../components/InputWithLabel/InputWithLabel'
import {SelectCountryWithLabel} from '../../../components/selectWithLabel/selectCountryWithLabel'
import {SelectStateWithLabel} from '../../../components/selectWithLabel/selectStateWithLabel'

export const SecuritiesDilivery = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            streetAddress: '',
            city: '',
            zipCode: '',
            State: '',
            Country: '',
            Email: '',
            telephoneNumber: '',
            NewIssueSecurities: '',
        },
        validationSchema: Yup.object({
            NewIssueSecurities: Yup.string().required(),
        }),
        onSubmit: (values) => {
            console.log(values)
            alert(JSON.stringify(values, null, 2))
            navigate('/security-holder/pay')
        },
    })

    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav enableProps={true} />
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="w-11/12 md:w-9/12 mx-auto flex flex-col py-12 space-y-10 accent-reddark">
                <div className="space-y-1">
                    <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                            Securities to be delivered to
                        </div>
                        <Checkbox
                            text="Securities to be delivered to"
                            name="NewIssueSecurities"
                            id="NewIssueSecurities1"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={'Securities to be delivered to'}
                        />
                        <Checkbox
                            text="Alternate address"
                            name="NewIssueSecurities"
                            id="NewIssueSecurities2"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={'Alternate address'}
                        />
                    </div>
                    {formik.errors.NewIssueSecurities && (
                        <div className="text-reddark text-xs">
                            {formik.errors.NewIssueSecurities}
                        </div>
                    )}
                </div>
                <hr className="border border-gray2" />
                {formik.values.NewIssueSecurities === 'Alternate address' && (
                    <div className="mt-10 space-y-8">
                        <div className="grid md:grid-cols-2 gap-4 md:gap-16">
                            <div className="space-y-3">
                                <InputWithLabel
                                    label="Street Address"
                                    placeholder="Please enter street"
                                    name="streetAddress"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.streetAddress}
                                    errors={formik?.errors?.streetAddress}
                                    touched={formik?.touched?.streetAddress}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="City"
                                    placeholder="Please enter city"
                                    name="city"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                    errors={formik?.errors?.city}
                                    touched={formik?.touched?.city}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="Zip/Postal Code"
                                    placeholder="Please enter Zip code"
                                    name="zipCode"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.zipCode}
                                    errors={formik?.errors?.zipCode}
                                    touched={formik?.touched?.zipCode}
                                    width="full"
                                />
                            </div>
                            <div className="space-y-3">
                                <SelectStateWithLabel
                                    label="State"
                                    name="State"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.State}
                                    errors={formik?.errors?.State}
                                    touched={formik?.touched?.State}
                                    width="full"
                                />
                                <SelectCountryWithLabel
                                    label="Country"
                                    name="Country"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Country}
                                    errors={formik?.errors?.Country}
                                    touched={formik?.touched?.Country}
                                    width="full"
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="mt-10  flex flex-col items-end justify-end w-full ">
                    <button className="btn-brown w-44 " type="submit">
                        CONTINUE
                    </button>
                </div>
            </form>
            {formik.values.NewIssueSecurities === 'Alternate address' ? (
                <Footer />
            ) : (
                <div className="bottom-0 absolute w-full">
                    <Footer />
                </div>
            )}
        </div>
    )
}
