import {Header} from '../../../components/header/header'

export const UserPrivacyPolicy = () => {
    return (
        <div className="w-full h-full flex flex-col items-center "> 
            <Header heading="Privacy Policy" description={' '}/>
            <div className="w-11/12 md:w-9/12 mx-auto py-10">
                <div className="Roboto-Bold text-lg sm:text-xl md:text-2xl text-blue-base mx-auto text-center">
                    ODYSSEY TRUST COMPANY
                </div>
                <div className="space-y-4 mt-10 sm:mt-14">
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            PRIVACY STATEMENT (WEBSITE)
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            Odyssey Trust Company ("Odyssey") recognizes how
                            important your personal privacy is to you. We are
                            required to collect, use and disclose personal
                            information for purposes that are reasonable and to
                            do so in a manner that recognizes your right to
                            protection of that information. When we refer to
                            personal information, we mean any information that
                            identifies you as an individual and includes
                            information with respect to your name, address,
                            socia insurance number or tax identification number,
                            securities/asset ownership information and other
                            financial information. Odyssey may receive personal
                            information about you from you directly, but often
                            we receive information about you from a third party
                            authorized by you, such as our clients (public ar
                            private issuers of securities), your employer if we
                            are administering their plan, or a brokerage firm or
                            other intermediary that is requesting a transfer of
                            securities.
                            <br />
                            <br />
                            Odyssey is committed to protection of personal
                            information entrusted to us and as such our
                            employees are regularly trained on the policies and
                            procedures we use in order to keep your information
                            secure. We have adopted the following standards and
                            practices.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            COLLECTION AND CONSENT
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            Odyssey has identified the purpose(s) for which we
                            collect personal information will only do so when it
                            is necessary and reasonable to collect this
                            information for its identified purpose. <br />
                            <br />
                            We collect personal information directly from you,
                            the individual, unless the applicable legislation or
                            the individual authorized the collection from
                            another source. We will inform you, either verbally
                            or in writing, before or at the time of collection,
                            why the personal information is needed and how it
                            will be used in a clear and reasonably understood
                            manner. <br />
                            <br />
                            Odyssey will obtain your consent for the collection
                            and use of your personal information verbally, in
                            writing or electronically on our website. Implied
                            consent may be used when personal information is
                            supplied to us on a request to transfer or issue
                            securities. <br />
                            <br />
                            When using your personal information that has
                            previously been collected for a new purpose not
                            previously identified, Odyssey will inform you of
                            the new purpose and obtain consent prior to its use.
                            There are limited circumstances where Odyssey is not
                            required to collect consent to disclose private
                            information. This includes cases of detection of
                            fraud, for law enforcement or under specified
                            exemptions in the privacy laws. <br />
                            <br />
                            If you access an online service offered by Odyssey,
                            we may use a tool called "Google Analytics" which
                            transmits website traffic data to Google servers in
                            the United States in order to help us understand
                            website traffic and webpage usage. We do not combine
                            the information collected through the use of Google
                            Analytics with personal information. Google
                            Analytics does not identify individual users or
                            associate your IP address with any other data held
                            by Google. Google's ability to use and share
                            information collected by Google Analytics is
                            restricted by the Google Analytics Terms of Use and
                            the Google Privacy Policy. You can opt out of Google
                            Analytics if you disable or refuse the cookie,
                            disable JavaScript, or use the opt-out service
                            provided by Google.
                            <br />
                            <br />
                            Odyssey's website may contain links to third-party
                            websites that are not subject to this Privacy
                            Statement. Odyssey is not responsible for any
                            personal information you may submit to a third-party
                            website accessed from Odyssey's website. Please
                            refer to the privacy policies/statements on any
                            third- party website to determine their personal
                            information standards and practices. Please also
                            note that any websites to which our website may be
                            linked may also make use of their own cookies to
                            collect information from you. Most browsers will
                            automatically accept a cookie, but it may be
                            possible to set your browser to notify you prior to
                            it being sent at which point you can accept or
                            reject it.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            USE, DISCLOSURE AND RETENTION
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            Odyssey will use personal information only for the
                            reasonable purpose(s) for which it was collected.
                            For example, a registered securityholder's name and
                            address will be maintained in order to deliver
                            continuous disclosure documents such as proxy
                            materials for a securityholder meeting on behalf of
                            the issuer, offering materials, and dividends and/or
                            interest cheques. A registered securityholder's last
                            known name and address may also be used as a means
                            of locating him or her if we are holding unclaimed
                            property that requires us to complete a legislated
                            search for the owner. A securityholder's social
                            insurance number or taxpayer identification number
                            will be used only for tax reporting purposes on tax
                            slips and to the CRA or IRS when taxable
                            disbursements have been made.
                            <br />
                            <br />
                            With respect to both to current and former
                            securityholders, Odyssey does not share non-public
                            personal information with any non-affiliated third
                            party except as necessary to process a transaction,
                            service your account or as permitted by law. Our
                            affiliates and outside service providers with whom
                            we share information are legally bound not to
                            disclose the information in any manner, unless
                            permitted by law or other governmental processes.
                            <br />
                            <br />
                            We will keep personal information only for as long
                            as it is necessary to fulfill the business or legal
                            purpose(s) for which it was collected, in
                            coordination with the minimum retention period
                            required by applicable legislation. While certain
                            privacy legislation affords you the right to request
                            that your information be deleted from our systems,
                            if this information is needed to perform the
                            services that we carry out on behalf of issuers
                            whose securities you hold or have held as a
                            registered owner, we are unable to delete your data
                            if requested.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            SAFEGUARDS AND ACCURACY
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            We strive to restrict access to your personal
                            information to those employees who need to know the
                            information to provide our services to you and the
                            issuer(s) in which you hold securities. We maintain
                            physical, procedural and technological safeguards to
                            protect your personal information.
                            <br />
                            <br />
                            If we receive an email message from you that
                            includes personal information, we may use that
                            information to reply or provide the requested
                            service, but please note that email and other
                            Internet communications are not necessarily secure
                            against interception. If your communication includes
                            sensitive information including things like your
                            social insurance number or banking information, you
                            should not send it electronically unless your
                            browser indicates that the access to our website is
                            secure. We undertake periodic reviews of our
                            security policies and procedures and technological
                            safeguards to ensure that our systems are secure and
                            protected, including by internal and external
                            audits.
                            <br />
                            <br />
                            To conduct our business and to meet regulatory
                            requirements, your information may be transferred to
                            other companies in or outside of Canada that provide
                            data processing, storage or other services
                            (including auditors, bankers, mailing houses and
                            regulatory screening services). We will not provide
                            more information than is reasonably necessary for
                            them to perform the services for which they are
                            engaged, and contractually require that they not
                            store, analyze or use that information for purposes
                            other than to carry out the intended services.
                            Should the data or information that we transfer to
                            the data processors, service providers or other
                            parties described above be transferred outside of
                            Canada, it could be available to a foreign
                            government or its agencies under the laws of that
                            country.
                            <br />
                            <br />
                            Odyssey will minimize the possibility of using
                            incorrect or incomplete information when making a
                            decision that affects an individual or when
                            disclosing an individual's information to another
                            organization. This is achieved by making reasonable
                            efforts to ensure that the personal information we
                            collect is accurate and complete.
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            OPENNESS AND ACCOUNTABILITY
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            Odyssey is providing this Privacy Statement to be
                            open about our privacy practices and has developed
                            the referenced internal policies and procedures and
                            safeguards to remain accountable to every individual
                            whose personal information we collect or receive.
                            Each of our employees is responsible for the
                            personal information under his or her control and
                            through ongoing training, employees remain informed
                            of the importance of compliance with our policies
                            and procedures.
                            <br />
                            <br />
                            We have appointed a Chief Privacy Officer who is
                            responsible for Odyssey's compliance program and for
                            responding to privacy-related inquiries and may be
                            contacted at Privacy@odysseytrust.com or in writing
                            to Odyssey Trust Company, 350- 409 Granville St,
                            Vancouver, BC V6C 1T2.
                        </div>
                    </div>

                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                            ACCESS AND RECOURSE
                        </div>
                        <div className="text-xs md:text-sm Roboto-Regular">
                            Odyssey is providing this Privacy Statement to be
                            open about our privacy practices and has developed
                            the referenced internal policies and procedures and
                            safeguards to remain accountable to every individual
                            whose personal information we collect or receive.
                            Each of our employees is responsible for the
                            personal information under his or her control and
                            through ongoing training, employees remain informed
                            of the importance of compliance with our policies
                            and procedures.
                            <br />
                            <br />
                            We have appointed a Chief Privacy Officer who is
                            responsible for Odyssey's compliance program and for
                            responding to privacy-related inquiries and may be
                            contacted at Privacy@odysseytrust.com or in writing
                            to Odyssey Trust Company, 350- 409 Granville St,
                            Vancouver, BC V6C 1T2.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
