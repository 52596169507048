import { useEffect, createRef, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactMaterialTable } from '../../components/table/customtable'
import { onGetClientDropdownList, onGetEvents } from '../../redux/actions/admin.actions'

export const AdminEventScreen = () => {

    const dispatch = useDispatch<any>()
    const tableRef = createRef();
    const navigate = useNavigate()
    const { events, client_dropdown_list } = useSelector((state: any) => state.AdminReducer, shallowEqual)
    const [queryParams, setQueryParams] = useState<
        {
            search: string
            status: string
        }[]
    >([])
    useEffect(() => {
        dispatch(onGetClientDropdownList(navigate))
    }, [])

    const [tableData, settableData] = useState([
        {
            event_type: 'event 1',
            event_date: '12/23/2023',
            total_securities_exchanged: '12/23/2023',
            total_securities_unexchanged: '12/23/2023',
            event_status: 'COMPLETED',
        },
        {
            event_type: 'event 2',
            event_date: '12/23/2023',
            total_securities_exchanged: '12/23/2023',
            total_securities_unexchanged: '12/23/2023',
            event_status: 'PENDING',
        },
        {
            event_type: 'event 3',
            event_date: '12/23/2023',
            total_securities_exchanged: '12/23/2023',
            total_securities_unexchanged: '12/23/2023',
            event_status: 'ONGOING',
        },
    ])

    const [searchedInputStatus, setsearchedInputStatus] =
        useState<boolean>(false)
    const [searchedList, setsearchedList] = useState<
        {
            event_type: string
            event_date: string
            total_securities_exchanged: string
            total_securities_unexchanged: string
            event_status: string
        }[]
    >([])

    var columns = [
        {
            field: 'id',
            title: 'EVENT ID',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium text-blue-base Roboto-Medium">
                        {rowData?.id}
                    </div>
                </div>
            ),
        },
        {
            field: 'event_name',
            title: 'EVENT NAME',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.event_name}</div>
                </div>
            ),
        },
        {
            field: 'event_type',
            title: 'EVENT TYPE',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.event_type}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_securities_exchanged',
            title: '',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="text-brown Roboto-Bold"
                        onClick={() => navigate(`/admin-event-detail/${rowData?.id}`)}
                    >SEE DETAILS</div>
                </div>
            ),
        },
        {
            field: 'action',
            title: '',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="text-brown Roboto-Bold"
                        onClick={() => navigate(`/admin-events/${rowData?.id}/security-holders`)}
                    >View SecurityHolders</div>
                </div>
            ),
        },
        {
            field: 'action',
            title: '',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="text-brown Roboto-Bold"
                        onClick={() => navigate(`/admin-events/${rowData?.id}?name=${rowData?.client_company_name}`)}
                    >View Access Codes</div>
                </div>
            ),
        },
    ]

    const onEnterInput = (e: any, searchedBy: string, input: boolean) => {
        let query = { ...queryParams }
        if (e.target.value) {
            query[searchedBy] = e.target.value
        } else {
            delete query[searchedBy]
        }
        setQueryParams(query)
        tableRef.current && (tableRef.current as any).onQueryChange({ page: 0 })
    }

    return (
        <div className="page-padding py-10">
            <div className="flex items-center w-full space-y-4 md:space-y-0 flex-col md:flex-row md:w-8/12 lg:w-1/2 md:space-x-10">
                <div className="h-7 w-full sm:h-8 md:h-10 relative flex items-center space-x-3 px-2 md:px-4 bg-white text-gray border  border-bordergray">
                    <input
                        className={`h-full text-gray w-full outline-none px-2 text-xs sm:text-sm 2xl:text-base pace-x-2 sm:space-x-4 2xl:space-x-8 `}
                        onChange={(e: any) =>
                            onEnterInput(e, 'search', true)
                        }
                        placeholder="Search"
                    />
                    <FaSearch className="text-sm md:text-lg lg:text-xl 2xl:text-2xl bg-white text-home-layout-gray" />

                    {searchedInputStatus && searchedList.length > 0 && (
                        <div className="w-full bg-white z-50 p-2 absolute -left-3 text-xs top-7 border border-meeting-cardBorder rounded-sm">
                            {searchedList.map((item, index) => (
                                <div key={index}>{item.event_type}</div>
                            ))}
                        </div>
                    )}
                </div>
                <div
                    className={`md:w-1/3 h-7 sm:h-8 flex items-center px-2 text-blue-normal md:h-10 outline-none border border-blue-base Roboto-Medium text-sm`}>
                    <select
                        onChange={(e: any) =>
                            onEnterInput(e, 'client_id', false)
                        }
                        className={`h-full outline-none w-full`}>
                        <option value="">ISSUER</option>
                        {client_dropdown_list?.map((item: any, index: any) => (
                            <option key={index} value={item.id}>
                                {item.client_company_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="py-10">
                <ReactMaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={(query) => (
                        new Promise(async (resolve, reject) => {
                            const page = query.page ? query.page + 1 : 1
                            const params = { ...queryParams, page }
                            const result = await dispatch(onGetEvents(params, navigate))
                            return resolve(result)
                        }))
                    }
                    searchbar={false}
                    toolbar={false}
                    exportbtn={false}
                    fontSize="16px"
                    pagging={true}
                    id="table"
                />
            </div>
        </div>
    )
}
